<style scoped>
.selected-type {
  border-bottom:2px solid #CA952B;
  width:100%;
}

.card-agent {
	background-color:#fefefe;
	border-radius: 10px;
}

.cagent-image {
	width: 30%;

}
.cagent-image>img {
	width: 80%;

}

.cagent-details {
	width: 50%;
	padding-left: 20px;
}

.cagent-ws {
	display: flex;
	width: 20%;
	padding-right: 5px;
	justify-content: flex-end;
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
</style>

<template> 
	<div style="width:100%; background-color:#fefefe;" class="p-4 pt-5">
		<div class="row">
			<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
				<h5>
					Landcartel Agents
				</h5>
			</div>
			<div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
				Search all Landcartel Network members to find a Landcartel Pro in your area. You can search by broker or agent name, the 
				broker's location city or county, or the city or county where a broker's properties are for sale.
			</div>

			<div class="pt-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
				<div class="d-flex" style="width: 150px; justify-content: space-between;">
					<div class="select-type" @click="changeTab('Location')">
						<div class="px-4 py-2" align="center" :class="{ 'selected-type': selectedTab === 'Location' }" style="cursor:pointer">Location</div>
					</div>
					<div class="select-type" @click="changeTab('Name')">
						<div class="px-4 py-2" align="center" :class="{ 'selected-type': selectedTab === 'Name' }" style="cursor:pointer">Name</div>
					</div>
				</div>
			</div>

			<div class="pt-2 col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
				<input v-model="this.search" class="form-control" type="text" style="width: 100%; height: 38px" :placeholder="'Search ' + this.selectedTab"/>
			</div>

			<div class="pt-2 col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 d-flex">
				<div>
					<button @click="getAgent()" class="btn btn-primary btn-block" style="width: 100px;height: 37px" align="center">Search</button>
				</div>
				<div class="ps-2">
					<button v-if="this.beenFilter" @click="resetFilter()" class="btn btn-tertiary btn-block" style="border: #CCC 1px solid; width: 100px;height: 37px" align="center">Reset</button>
				</div>
			</div>

		</div>
	</div>

	<div class="p-4" style="width: 100%;" v-if="this.tableLoader == false">
		<div v-if="this.emptyListing">
			<div class="row" style="justify-content: space-around">
				<div class="mb-3 col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" v-for="(item, index) in this.dataAgent" :key="index">
					<div class="card-agent row" style="box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px; height: 109px;">
						<div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
							<div class="d-flex" style="justify-content: center; align-items: center; height: 100%;">
								<div><img style="border-radius:50%; width:80px;" :src="item.image || require('../../assets/images/user/avatar.png')" /></div>
							</div>
						</div>
						
						<div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
							<div class="pt-3" style="font-weight: 500; font-size: 1rem">{{ item.name }}</div>
							<div class="" style="font-weight: 300; font-size: 0.7rem">{{ item.city }}</div>
							<div class="" style="font-weight: 300; font-size: 0.7rem">{{ item.state }}</div>
							<div class="pb-3" style="font-weight: 300; font-size: 0.8rem">{{ item.phone }}</div>
						</div>
					
						<div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">
							<div style="flex-direction: column; align-items: flex-end; justify-content: space-around" class="d-flex py-3">
								<div class="pe-2">
									<a :href="'https://api.whatsapp.com/send?phone=' + item.phone" @click="checkPointWs()">
										<svg data-v-8d1dae30="" xmlns="http://www.w3.org/2000/svg" fill="#000" viewBox="0 0 448 512" width="23"><path data-v-8d1dae30="" d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"></path></svg>
									</a>
								</div>
								<div class="pe-2">
									<a :href="'mailto:' + item.email">
										<svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" stroke-width="50" fill="#000" class="bi bi-envelope" viewBox="0 0 16 16">
											<path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
										</svg>
									</a>
								</div>
							</div>
							
						</div>

					</div>
				</div>
			</div>
		</div>
		<div v-else class="px-4 d-flex" style="flex-direction: column; align-items: center; width: 100%;">
			<div>
				<img style="border-radius: 5px; width: 300px;" alt="" src="../../assets/images/no_data_image.png" />
			</div>
			<div style="text-align: center;">
				<h6>No result found!</h6>
			</div>
		</div>
	</div>
	<div v-else>
		<div class="d-flex" style="flex-direction: column; justify-content: center;">
			<h6 class="mb-0 text-center">Please Wait...</h6>
			<div class="loader-box">
				<div class="loader-3"></div>
			</div>
		</div>
	</div>

</template>
<script>
import { toast } from "vue3-toastify";
import { 
	listofagent,
    addPointUser,
	getActivityPoint 
} from "@/helper/apiservice/axios-http";

export default {
    components: {
    },
    data() {
        return {
			tableLoader: false,
			emptyListing: false,
			beenFilter: false,
          	selectedTab: 'Location',
			search: null,
			dataAgent: [],
			scrollY: 0,
			action: null,
			status: 0,
			point: 0,
			buffer: false,
        };
    },
    computed: {
    },
    mounted() {
		this.checkCondition();
		window.addEventListener("scroll", this.handleScroll);
        this.getAgent();
    },
	beforeDestroy() {
		window.removeEventListener("scroll", this.handleScroll);
	},
    created() {
    },
    methods: {
        //TOAST NOTIFICATION
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
            // this.FilterHandling();
        },
		selectTab(tab) {
			this.selectedTab = tab;
		},

        async getAgent() {
            try {
				this.tableLoader = true;

                const dataPass = {
					type : this.selectedTab,
                    value : this.search,
					role: '2',
					approved: '1',
                };

				if (this.search != null) {
					this.beenFilter = true;
				}

                await listofagent(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.dataAgent = response.data.data;
						if (response.data.data.length === 0) {
                            this.emptyListing = false;
                        } else {
                            this.emptyListing = true;
                        }
                    } else {
                        this.notificationError(response.data.message);
                    }
					this.tableLoader = false;
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

		
        resetFilter() {
            this.search = null;
            this.beenFilter = false;
            this.getAgent();
        },

		handleScroll() {
			this.scrollY = window.scrollY;
			if (this.scrollY >= 10 && this.status == "1") {
				if (this.action == "Scroll") {
					this.addPoint();
				}
			}
		},
		checkPointOpen() {
			if (this.action == "Open" && this.status == "1") {
				this.addPoint();
			}
		},
		checkPointWs() {
			if (this.action == "Whatsapp" && this.status == "1") {
				this.addPoint();
			}
		},
        async checkCondition() {
            try {
                await getActivityPoint().then((response) => {
                    if (response.data.success === true) {
						this.action = response.data.data[3].user_action;
						this.status = response.data.data[3].status;
						this.point = response.data.data[3].limit_per_day;
						this.checkPointOpen();
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
		async addPoint() {
            const dataPass = {
                point: this.point,
                agentId: localStorage.getItem("user_id"),
            };
			if (this.buffer == false) {
				this.buffer = true;
				try {
					await addPointUser(dataPass).then((response) => {
						if (response.data.success === true) {
							this.notificationSuccess("Congratulation your got new point!");
							setTimeout(() => {
								this.buffer = false;
							}, 2000);
							
						} else {
                        	this.notificationError(response.data.message);
						}
					});
				} catch (error) {
					this.buffer = false;
					this.notificationError("Something went wrong!");
				}
			}
		},
    },
};
</script>
