<template>
    <div class="header-wrapper row m-0">
        <div class="left-header col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 p-0">
            <!-- <img class="img-fluid for-light" style="margin-top: 3px; width: 60px; height:60px" src="../../assets/images/logo/logo-header.png" alt="loginpage"/> -->
            <Logo />
        </div>
        <div class="nav-right col-xxl-7 col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pull-right right-header p-0 ms-auto">
            <ul class="nav-menus">
                <Profile />
            </ul>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay } from "swiper";
import "swiper/css";
import Profile from "./profile";
import Logo from "./logo";

export default {
    components: {
        Logo,
        Profile,
        Swiper,
        SwiperSlide,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({}),
    },
    methods: {},
    setup() {
        return {
            modules: [Autoplay],
        };
    },
};
</script>