<style scoped>
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #CA952B;
}
.activeButtonPage {
    color: #CA952B;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #d9d9d9 !important;
}
.selected {
    color: #CA952B;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #CA952B;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}

.input-date {
    padding: 10px;
    border: 1px solid #cccccc;
    border-radius: 7px;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template>
    <div class="padding-container">

        <div class="row">
            <div class="col-lg-12">
                <div class="py-3">
                    <h4>Overview</h4>
                    <div>View summary of your activities</div>
                </div>
                <!-- Top Summary -->
                <div class="row">
                    <div class="col-sm-12 col-xxl-4 py-2" v-if="this.roleUser == 2 || this.roleUser == 3 || this.roleUser == 4"> 
                        <div class="card small-widget mb-sm-0 p-3">
                            <div class="card-body primary">
                                <span class="f-light" style="font-size:1.3rem">Total Point</span>
                                <div class="d-flex align-items-end gap-1">
                                    <h4>{{ parseFloat(this.myPoint).toFixed(2) }}</h4>
                                </div>
                                <div class="bg-gradient"> 
                                    <svg class="stroke-icon svg-fill">
                                        <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xxl-4 py-2" v-if="this.roleUser == 2 || this.roleUser == 3 || this.roleUser == 4"> 
                        <div class="card small-widget mb-sm-0 p-3">
                            <div class="card-body primary">
                                <span class="f-light" style="font-size:1.3rem">Total Token</span>
                                <div class="d-flex align-items-end gap-1">
                                    <h4>{{ parseFloat(this.myToken).toFixed(2) }}</h4>
                                </div>
                                <div class="bg-gradient"> 
                                    <svg class="stroke-icon svg-fill">
                                        <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xxl-4 py-2" v-if="this.roleUser == 2 || this.roleUser == 3 || this.roleUser == 4"> 
                        <div class="card small-widget mb-sm-0 p-3">
                            <div class="card-body primary">
                                <span class="f-light" style="font-size:1.3rem">Subscription</span>
                                <div class="d-flex align-items-end gap-1">
                                    <h4>RM {{ this.current_subs_price }}</h4>
                                </div>
                                <div class="bg-gradient"> 
                                    <svg class="stroke-icon svg-fill">
                                        <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xxl-4 py-2" v-if="this.roleUser == 1"> 
                        <div class="card small-widget mb-sm-0 p-3">
                            <div class="card-body primary">
                                <span class="f-light" style="font-size:1.3rem">Number of User</span>
                                <div class="d-flex align-items-end gap-1">
                                    <h4>{{ this.totalUser }}</h4>
                                </div>
                                <div class="bg-gradient"> 
                                    <svg class="stroke-icon svg-fill">
                                        <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xxl-4 py-2" v-if="this.roleUser == 1"> 
                        <div class="card small-widget mb-sm-0 p-3">
                            <div class="card-body primary">
                                <span class="f-light" style="font-size:1.3rem">Number of Agent</span>
                                <div class="d-flex align-items-end gap-1">
                                    <h4>{{ this.totalAgent }}</h4>
                                </div>
                                <div class="bg-gradient"> 
                                    <svg class="stroke-icon svg-fill">
                                        <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 col-xxl-4 py-2" v-if="this.roleUser == 1"> 
                        <div class="card small-widget mb-sm-0 p-3">
                            <div class="card-body primary">
                                <span class="f-light" style="font-size:1.3rem">Number of Listing</span>
                                <div class="d-flex align-items-end gap-1">
                                    <h4>{{ this.totalListing }}</h4>
                                </div>
                                <div class="bg-gradient"> 
                                    <svg class="stroke-icon svg-fill">
                                        <use href="@/assets/svg/icon-sprite.svg#new-order"></use>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="py-3"></div>

                <div class="py-2 card" v-if="this.roleUser == 2 || this.roleUser == 3 || this.roleUser == 4"> 
                    <div class="p-3">
                        <h6>Share Your Page:</h6>
                        <div class="d-flex">
                            <div class="p-2 rounded" style="white-space: nowrap; padding-bottom: 20px; font-size: 0.9rem; border: #CCC solid 1px; max-width: 300px; height: 40px; overflow: scroll;">
                                {{ 'https://landcartel.swiftx.my/web/agent-page?id=' + this.$route.query.listing_id }}
                            </div>
                            <div class="ps-2">
                                <button @click="copyLink(this.$route.query.listing_id)" class="btn btn-tertiary py-2" style="width:180px; height: 40px; border: #CCC solid 1px; color:#414042" type="button">
                                    <div class="d-flex" style="justify-content: center;">
                                        <div>
                                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M15 3C15 2.44772 15.4477 2 16 2C19.3137 2 22 4.68629 22 8V16C22 19.3137 19.3137 22 16 22H8C4.68629 22 2 19.3137 2 16C2 15.4477 2.44772 15 3 15C3.55228 15 4 15.4477 4 16C4 18.2091 5.79086 20 8 20H16C18.2091 20 20 18.2091 20 16V8C20 5.79086 18.2091 4 16 4C15.4477 4 15 3.55228 15 3Z" fill="#000000"/>
                                                <path d="M3.70663 12.7845L3.16104 12.2746L3.70664 12.7845C4.09784 12.3659 4.62287 11.8265 5.17057 11.3274C5.72852 10.8191 6.26942 10.3905 6.69641 10.1599C7.06268 9.96208 7.75042 9.84035 8.40045 9.84848C8.62464 9.85128 8.81365 9.86944 8.9559 9.89472C8.96038 10.5499 8.95447 11.7469 8.95145 12.2627C8.94709 13.0099 9.83876 13.398 10.3829 12.8878L14.9391 8.61636C15.2845 8.2926 15.2988 7.74908 14.971 7.4076L10.4132 2.65991C9.88293 2.10757 8.95 2.48291 8.95 3.24856V5.16793C8.5431 5.13738 8.0261 5.11437 7.47937 5.13009C6.5313 5.15734 5.30943 5.30257 4.4722 5.88397C4.36796 5.95636 4.26827 6.03539 4.17359 6.11781C2.49277 7.58092 2.11567 9.90795 1.8924 11.7685L1.87242 11.935C1.74795 12.9722 3.02541 13.5134 3.70663 12.7845ZM9.35701 11.7935L9.70204 12.1615L9.35701 11.7935C9.35715 11.7934 9.35729 11.7932 9.35744 11.7931L9.35701 11.7935Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round"/>
                                            </svg>
                                        </div>
                                        <div class="ps-2">
                                            Copy Link
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div v-if="this.tableloader === false">
                    <div class="card">
                        <div class="p-4 d-flex" style="align-items: center">
                            <div class="col-2 d-flex" style=" font-size: 1rem; font-weight: 500; align-items: center; ">
                                <span>Listing Performance</span>
                            </div> -->
                            <!-- <div class="col-10 d-flex">
                                <div class="faq-form" style="width: 70%">
                                    <input class="form-control" v-model="inputText" @input="FilterHandling(inputText)" type="text" placeholder="Search name.."/>
                                    <vue-feather class="search-icon" type="search" ></vue-feather>
                                </div>
                            </div> -->
                            <!-- <div class="col-1 d-flex" style="cursor: pointer; align-items: center">
                                <div style=" font-size: 1rem; font-weight: 450; padding-right: 10px; " >
                                    Filter
                                </div>
                                <div style="position: relative; top: 2px">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="#CA952B" class="bi bi-caret-down-fill" viewBox="0 0 16 16">
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                                    </svg>
                                </div>
                            </div> -->
                        <!-- </div> -->

                        <!-- DATA Table -->
                        <!-- <div class="row py-1 px-4">
                            <div style="border-bottom: 1px solid #cccccc">
                                <div class="col-sm-10 row">
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '', }" @click="changeTab('')">
                                        All
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '0', }" @click="changeTab('0')">
                                        Success
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '1', }" @click="changeTab('1')">
                                        Approval
                                    </div>
                                    <div style="cursor: pointer" class="p-3 col-sm-2" align="center" :class="{ selected: selectedTab === '2', }" @click="changeTab('2')">
                                        Ended
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- DATA Table -->
                        <!-- <div v-if="this.landListing">
                            <div class="search-table-outter wrapper p-4" style="height: 420px">
                                <table class="search-table inner">
                                    <tr style="height: 20px">
                                        <th style="width: 2%">Ranking</th>
                                        <th style="width: 30%;">Title</th>
                                        <th style="width: 18%;">Date Posted</th>
                                        <th style="width: 20%;">Price (RM)</th>
                                        <th style="width: 10%;text-align: center;">Action</th>
                                    </tr>
                                    <tr style="height: 60px" v-for="(item,index) in this.landListing" :key="index">
                                        <td>{{ index + 1 }}.</td>
                                        <td>{{ item.land_title }}</td>
                                        <td>{{ item.created_at }}</td>
                                        <td>{{ item.land_price }}</td>
                                        <td style="text-align: center;">
                                            <div class="d-flex">
                                                <button class="btn btn-primary btn-block" style="width:80%" type="button" @click="bump(item)" data-bs-toggle="modal" data-bs-target="#privacy">Bump Now</button>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <div align="center" style="height: 300px" v-else>
                            <img style=" border-radius: 5px; width: 240px; height: 220px; " alt="" src="../../assets/images/no_data_image.png"/>
                            <h6>No Data</h6>
                        </div> -->

                    <!-- </div>
                </div> -->

                <!-- <div v-else style="padding-top: 30px">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="modal fade modal-bookmark" id="privacy" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirmation</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div style="max-height: 60vh; overflow: scroll; overflow-x: hidden;" class="ps-3 pe-3">
                        <div class="modal-body row" style="justify-content: space-between">
                            <p>1 token will be deducted from your wallet. are you sure?</p>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12 d-flex" style="justify-content: flex-end">
                            <div>
                                <button @click="confirmDeduct()" data-bs-dismiss="modal" class="btn btn-color" type="button" style="width: 100%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {
    getLandListing,
    userProfile,
    boostLandListing,
    getDashboardAdmin,
    currentSubscription,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Dashboard",

    components: {},

    data() {
        return {
            totalUser: 0,
            totalAgent: 0,
            totalListing: 0,

            roleUser: localStorage.getItem("access"),
            landListing: null,
            myToken: 0,
            myPoint: 0,
            searchText: "",
            // selectedTab: "",
            tableData: null,
            tableDataResult: "",
            totalAgents: 0,
            totalTransactions: 0,
            totalReferral : 0,
            tableloader: false,
            indexNumbering: 1,
            currentPage: 1,
            itemsPerPage: 5,
            maxPage: 1,
            fromDate:null,
            toDate: null,
            falseDate: false,
            id_post: null,
            current_subs_price: null,
        };
    },
    
    setup() {},

    beforeMount() {},

    mounted() {},

    created() {
        this.loadDashboardData();
        this.loadProfileDetails();
        this.dashboardAdmin();
        this.currentSubscriptionUser();
    },

    computed: {
        limitedPages() {
            const totalPages = Math.ceil(
                this.tableData.length / this.itemsPerPage
            );
            const displayPages = 3; // Number of pages to display
            const threshold = 3; // Threshold after which pages should shift

            let startPage = Math.max(
                1,
                this.currentPage - Math.floor(displayPages / 2)
            );
            let endPage = Math.min(totalPages, startPage + displayPages - 1);

            if (this.currentPage >= threshold) {
                startPage = this.currentPage - 1;
                endPage = Math.min(
                    totalPages,
                    this.currentPage + displayPages - 2
                );
            }

            return Array.from(
                { length: endPage - startPage + 1 },
                (_, index) => startPage + index
            );
        },
    },

    methods: {
        copyLink(id) {
            const link = `https://landcartel.swiftx.my/web/property-details?listing_id=${id}`;
            
            // Copy the link to the clipboard
            navigator.clipboard.writeText(link)
            .then(() => {
                this.notificationSuccess("Link copied!");
            })
            .catch(err => {
                this.notificationError("Something went wrong!");
            });
        },

        async currentSubscriptionUser() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                };
                await currentSubscription(dataPass).then((response) => {
                    if (response.data.success == true) {
                        if (response.data.data=='No active subscription found for this user') {
                            this.current_subs_price = '0/month';
                        } else {
                            this.current_subs_price = response.data.data.package_details.price;
                        }
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                // console.log(error)
                this.notificationError("Something went wrong!");
            }
        },

        async dashboardAdmin() {
            try {
                await getDashboardAdmin().then((response) => {
                    if (response.data.success === true) {
                        this.totalUser = response.data.data.total_access_user;
                        this.totalAgent = response.data.data.total_access_agent;
                        this.totalListing = response.data.data.number_of_listing;
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async confirmDeduct() {
            
            const dataPass = {
                usr_main_id: localStorage.getItem("user_id"),
                id: this.id_post,
            };

            try {
                await boostLandListing(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.loadDashboardData();
                        this.notificationSuccess("Success!");
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
            this.id_post = null;
        },

        bump(item) {
            // console.log(item);
            this.id_post = item.id;
        },

        async loadProfileDetails() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                };
                await userProfile(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.myToken = response.data.data.token;
                        this.myPoint = response.data.data.point;
                        
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        //FILTER CHANGE TAB
        // changeTab(tabProgressName) {
        //     this.selectedTab = tabProgressName;
        //     this.FilterHandling();
        // },
        
        // PREVIOUS PAGE FUNCTION
        prevPage() {
            if (this.currentPage > 1) {
                this.currentPage--;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // NEXT PAGE FUNCTION
        nextPage() {
            if (this.currentPage < this.maxPage) {
                this.currentPage++;
                this.PageIndexNumber();
                this.PaginationHandling();
            }
        },

        // SEND TO FIRST PAGE
        setFirstPage() {
            this.currentPage = 1;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        // SEND TO LAST PAGE
        setLastPage() {
            this.currentPage = this.maxPage;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        goToPage(page) {
            this.currentPage = page;
            this.PageIndexNumber();
            this.PaginationHandling();
        },

        PageIndexNumber() {
            if (this.currentPage == 1) {
                this.indexNumbering = 1;
            } else {
                if (this.currentPage == 2) {
                    this.indexNumbering = this.itemsPerPage;
                } else {
                    this.indexNumbering =
                        (this.currentPage - 1) * this.itemsPerPage;
                }
            }
        },

        // FILTER FUNCTION DATA MANAGEMENT
        FilterHandling(text) {
            //FILTER FIRST ORIGINAL DATA GIVEM
            // if (this.selectedTab != "") {
            //     this.tableDataResult = this.tableData.filter(
            //         (item) => item.client_status === this.selectedTab
            //     );
            // } else {
            //     //If FILTER ALL, FETCH ALL DATA FROM API
            //     this.tableDataResult = this.tableData;
            // }
            this.tableDataResult = this.tableData;
            if (text != undefined) {
                this.searchText = text;
            }

            // Filter based on search text
            if (this.tableDataResult !== null) {
                // Filter based on search text
                if (this.searchText.trim() !== "") {
                    const searchText = this.searchText.toLowerCase().trim();
                    this.tableDataResult =
                        this.tableDataResult.filter(
                            (item) =>
                                (item.full_name &&
                                    item.full_name
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if package is not null
                                (item &&
                                    item.contact_number &&
                                    item.contact_number
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if full_name is not null
                                (item &&
                                    item.company &&
                                    item.company
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if contact_number is not null
                                (item &&
                                    item.brand_name &&
                                    item.brand_name
                                        .toLowerCase()
                                        .includes(searchText)) || // Check if contact_number is not null
                                (item &&
                                    item.business_industry &&
                                    item.business_industry
                                        .toLowerCase()
                                        .includes(searchText))
                        );
                }
            }

            //RESET PAGE BACK TO 1
            this.currentPage = 1;
            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            //ASSIGN HOW MANY PAGES FROM THE FILTERED DATA RESULT
            this.maxPage = Math.ceil(
                this.tableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.tableDataResult.length > 0) {
                this.tableDataResult = this.tableDataResult.slice(
                    startIndex,
                    endIndex
                );
            } else {
                this.tableDataResult = null;
            }
        },

        PaginationHandling() {
            //FILTER THE SELECTED DATA
            // if (this.selectedTab != "") {
            //     this.tableDataResult = this.tableData.filter(
            //         (item) => item.client_status === this.selectedTab
            //     );
            // } else {
            //     this.tableDataResult = this.tableData;
            // }
            // this.tableDataResult = this.tableData.data_transaction;
            // console.log(this.tableDataResult)
            // Apply text search filter if searchText is not empty
            if (this.searchText != "") {
                const searchText = this.searchText.toLowerCase().trim();
                this.tableDataResult = this.tableDataResult.filter(
                    (item) =>
                        (item.full_name &&
                            item.full_name
                                .toLowerCase()
                                .includes(searchText)) || // Check if package is not null
                        (item.contact_number &&
                            item.contact_number
                                .toLowerCase()
                                .includes(searchText)) || // Check if full_name is not null
                        (item.company &&
                            item.company.toLowerCase().includes(searchText)) || // Check if full_name is not null
                        (item.brand_name &&
                            item.brand_name
                                .toLowerCase()
                                .includes(searchText)) || // Check if full_name is not null
                        (item.business_industry &&
                            item.business_industry
                                .toLowerCase()
                                .includes(searchText)) // Check if contact_number is not null
                );
            }

            // Slice the data based on pagination
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;

            this.maxPage = Math.ceil(
                this.tableDataResult.length / this.itemsPerPage
            );

            //Assign FINAL Value OF PAGE DATA
            if (this.tableDataResult.length > 0) {
                this.tableDataResult = this.tableDataResult.slice(
                    startIndex,
                    endIndex
                );
                this.tableDataResult.forEach((dataObj) => {
                    if (this.currentPage > 1) {
                        this.indexNumbering++;
                    }

                    dataObj.numbering = this.indexNumbering;
                    if (this.currentPage == 1) {
                        this.indexNumbering++;
                    }
                });
            } else {
                this.tableDataResult = null;
            }
        },

        //WHEN DATE FROM TO TO IS APPLIED
        applyFilter() {
            if (this.fromDate!=null && this.toDate!=null) {
                if (this.fromDate<this.toDate) {
                    this.falseDate=false;
                    // console.log(this.fromDate);
                    // console.log(this.toDate);
                    // this.loadDashboardData();
                } else {
                    this.falseDate=true;
                }
            }
        },

        //--------API HANDLING -----------------//
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        formatDateTime(dateTimeString) {
            const [year, month, day] = dateTimeString.split("-");
            const formattedDateTime = `${day}-${month}-${year}`;
            return formattedDateTime;
        },

        
        // land
        async loadDashboardData() {
            this.tableloader = true;
            const dataPass = {
                search: null,
                city: null,
                state: null,
                area: null,
                price: null,
            };
            try {
                await getLandListing(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.landListing = response.data.data;
                        this.currentIndexes = this.landListing.map(() => 0);
                        this.tableloader = false;
                        this.tableDataResult = this.landListing;
                        this.tableData = this.landListing;

                        this.maxPage = Math.ceil(
                            this.tableDataResult.length / this.itemsPerPage
                        );
                    } else {
                        this.notificationError("Something went wrong!");
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>