<template>
    <div class="px-3 py-2 m-0 row header-wrapper" style="color:#fefefe; background-color: #191E32;">
        <div class="col-10 col-sm-9 col-md-7 col-lg-7 col-xl-5 d-flex">
            <div class="left-header">
                <div @click="route1()" style="cursor: pointer; height:60px;">
                    <img style="margin-top: 2px; height: 60px" src="@/assets/images/logo/logo-header.png" alt="loginpage"/>
                </div>
            </div>
            <div class="d-flex ps-2" style="align-items: center; font-weight: 500; font-size: 1rem !important;">
                Number #1 Land Marketplace
            </div>
        </div>
        <div class="right-header col-2 col-sm-3 col-md-5 col-lg-5 col-xl-7">
            <Profile />
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import Profile from "./profile";

export default {
    name: "Header",

    components: {
        Profile,
    },

    data() {
        return {};
    },

    computed: {
        ...mapState({}),
    },

    methods: {
        route1() {
            this.$router.push("/web/homepage");
        },
    },
};
</script>