<template>
    <div v-if="this.user.login">

        <div class="d-flex" style="flex-wrap: wrap; justify-content: space-around; color:#fefefe; font-size: 0.9rem; background-color: #191E32;">
            <div class="row" style="width: 100%;">

                <div class="mb-4 px-5 pt-4 col-xl-3 col-lg-3 col-sm-6 col-xm-12 d-flex" style="max-width: 320px; justify-content: center; align-items: center;">
                    <img class="img-fluid for-light" style="width: 180px" src="../assets/images/logo/logo-header.png" alt="loginpage"/>
                </div>

                <div class="mb-4 px-5 pt-4 col-xl-3 col-lg-3 col-sm-6 col-xm-12">
                    <div style="font-size: 1.1rem;">
                        {{ this.company_name }}
                    </div>
                    <div>
                        {{ this.company_roc }}
                    </div>
                    <div class="pt-2" style="max-width: 320px;">
                        {{ this.company_address }}
                    </div>
                    <div class="pt-2">
                        {{ this.company_phone }}
                    </div>
                    <div>
                        {{ this.email }}
                    </div>
                </div>

                <div class="mb-4 px-5 pt-4 col-xl-3 col-lg-3 col-sm-6 col-xm-12">
                    <div>
                        <a href="/web/aboutus" style="color:#fefefe;"><h6 style="font-weight: 400; font-size: 15px;">About Us</h6></a>
                    </div>
                    <div class="pt-2">
                        <a href="/web/contactus" style="color:#fefefe;"><h6 style="font-weight: 400; font-size: 15px;">Contact Us</h6></a>
                    </div>
                    <div class="pt-2">
                        <a href="/web/search" style="color:#fefefe;"><h6 style="font-weight: 400; font-size: 15px;">Listing</h6></a>
                    </div>
                    <div class="pt-2">
                        <a href="/web/list-of-agent" style="color:#fefefe;"><h6 style="font-weight: 400; font-size: 15px;">Find Agent</h6></a>
                    </div>
                    <div class="pt-2">
                        <a href="/web/corporate" style="color:#fefefe;"><h6 style="font-weight: 400; font-size: 15px;">Corporate</h6></a>
                    </div>
                </div>

                <div class="mb-4 px-5 pt-4 col-xl-3 col-lg-3 col-sm-6 col-xm-12">
                    <div>
                        <h6 style="font-weight: 400; font-size: 15px;">Follow us:</h6>
                        <div>
                            <div class="d-flex">
                                <div class="pt-3 pb-3 pe-3" style="cursor: pointer;">
                                    <a :href="'https://www.instagram.com/' + this.instagram">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fefefe" viewBox="0 0 448 512" width="20"><path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>
                                    </a>
                                </div>
                                <div class="p-3" style="cursor: pointer;">
                                    <a :href="'https://api.whatsapp.com/send?phone=' + this.whatsapp + '&text=Hi,Landcartel'">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fefefe" viewBox="0 0 448 512" width="20"><path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7 .9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
                                    </a>
                                </div>
                                <div class="p-3" style="cursor: pointer;">
                                    <a :href="'https://www.facebook.com/' + this.facebook">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#fefefe" viewBox="0 0 448 512" width="20"><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.3V327.7h-63V256h63v-54.6c0-62.2 37-96.5 93.7-96.5 27.1 0 55.5 4.8 55.5 4.8v61h-31.3c-30.8 0-40.4 19.1-40.4 38.7V256h68.8l-11 71.7h-57.8V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0 -48-48z"/></svg>
                                    </a>
                                </div>
                                <div class="p-3" style="cursor: pointer;">
                                    <a :href="'https://www.tiktok.com/@' + this.tiktok">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width="18" fill="#fefefe" style="margin-top: 3px;">
                                        <path d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pt-2" style="cursor: pointer;">
                        <h6 data-bs-toggle="modal" data-bs-target="#tnc" style="font-weight: 400; font-size: 15px;">Term of use</h6>
                    </div>
                    <div class="pt-2" style="cursor: pointer;">
                        <h6 data-bs-toggle="modal" data-bs-target="#privacy" style="font-weight: 400; font-size: 15px;">Privacy policy</h6>
                    </div>
                </div>
            </div>
        </div>

        <!-- <footer class="footer"  -->
        <footer style="background-color: #C89835; height: 35px;" :class="[ footer === 'footer-dark' ? 'footer-dark' : '', { close_icon: !togglesidebar },]">
            <div class="container-fluid">
                <div class="footer-copyright">
                    <div style="padding-top: 6px; font-weight:450; font-size: 0.7rem; justify-content: flex-end;" class="d-flex">
                        <div style="margin-top:3px;">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="11" fill="#000">
                                <path d="M256 48a208 208 0 1 1 0 416 208 208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM199.4 312.6c-31.2-31.2-31.2-81.9 0-113.1s81.9-31.2 113.1 0c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9c-50-50-131-50-181 0s-50 131 0 181s131 50 181 0c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0c-31.2 31.2-81.9 31.2-113.1 0z"/>
                            </svg>
                        </div>
                        <div style="margin-top:2px;">
                            &nbsp;{{ this.company_name }} {{ yearNow }}
                        </div>
                    </div>
                </div>
            </div>
        </footer>

        <!-- pop up tnc-->
        <div class="modal fade modal-bookmark" id="tnc" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Term of use</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div style="max-height: 60vh; overflow: scroll; overflow-x: hidden;" class="ps-3 pe-3">
                        <div class="modal-body row" style="justify-content: space-between" :style="{ whiteSpace: 'pre-line' }">
                            <p>{{ this.tnc }}</p>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12 d-flex" style="justify-content: flex-end">
                            <div>
                                <button data-bs-dismiss="modal" class="btn btn-color" type="button" style="width: 100%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- pop up privacy-->
        <div class="modal fade modal-bookmark" id="privacy" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Privacy policy</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div style="max-height: 60vh; overflow: scroll; overflow-x: hidden;" class="ps-3 pe-3">
                        <div class="modal-body row" style="justify-content: space-between" :style="{ whiteSpace: 'pre-line' }">
                            <p>{{ this.privacy }}</p>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12 d-flex" style="justify-content: flex-end">
                            <div>
                                <button data-bs-dismiss="modal" class="btn btn-color" type="button" style="width: 100%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                    Ok
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { getInfoCompany } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "footerpage",

    data() {
        return {
            instagram: null,
            whatsapp: null,
            facebook: null,
            tiktok: null,
            email: null,
            company_name: null,
            company_roc: null,
            company_address: null,
            company_phone: null,
            tnc: "",
            privacy: "",
            user: {
                login: true,
            },
            customizer: false,
        };
    },

    created() {
        this.checkLogin();
        this.sendRequest();
    },

    computed: {
        yearNow() {
            return new Date().getFullYear();
        },
        ...mapState({
            togglesidebar: (state) => state.menu.togglesidebar,
        }),
        ...mapGetters({
            footer: "layout/footer",
        }),
    },

    methods: {
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        checkLogin() {
            if (localStorage.getItem("token_credential") !== undefined) {
                const path = [
                "/web/homepage", 
                "/web/search", 
                "/web/list-of-agent",
                "/web/aboutus",
                "/web/contactus",
                "/web/corporate",
                "/web/joinus",
                "/web/privacypolicy",
                "/web/termncondition",
                "/web/property-details"];

                if (path.includes(this.$route.path)) {
                    this.user.login = true;
                } else {
                    this.user.login = false;
                }
            }
        },
        async sendRequest() {
            try {
                await getInfoCompany().then((response) => {
                    if (response.data.success == true) {
                        this.company_name = response.data.data.name;
                        this.company_phone = response.data.data.phone;
                        this.email = response.data.data.email;
                        this.instagram = response.data.data.instagram;
                        this.facebook = response.data.data.facebook;
                        this.whatsapp = response.data.data.whatsapp;
                        this.tiktok = response.data.data.tiktok;
                        this.company_roc = response.data.data.roc;
                        this.company_address = response.data.data.address;
                        this.tnc = response.data.data.tnc;
                        this.privacy = response.data.data.privacy;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
