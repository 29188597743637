import routers from "../../router/index";
import axios from "axios";

// 1
const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Origin, Content-Type, Accept",
        "Content-Type": "application/json",
        referrerPolicy: "no-referrer",
        Accept: "*/*",
    },
});

//
//
//
//
// contact us
export const sendEnquiry = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/enquiry",
            {
                state : dataPass.state,
                type : dataPass.type,
                phone : dataPass.phone,
                email : dataPass.email,
                name : dataPass.name,
                city : dataPass.city,
                remark : dataPass.remark,
            }
        );
        return $response;
    } catch (error) {
    }
};
export const sendCorporateEnquiry = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/enquiry-corporate",
            {
                company : dataPass.company,
                designation : dataPass.designation,
                name : dataPass.name,
                email : dataPass.email,
                phone : dataPass.phone,
                detail : dataPass.detail,
                posting : dataPass.posting,
            }
        );
        return $response;
    } catch (error) {
    }
};
export const sendAgentEnquiry = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/enquiry-agent",
            {
                name : dataPass.name,
                email : dataPass.email,
                phone : dataPass.phone,
                detail : dataPass.detail,
                title : dataPass.title,
                user_id: dataPass.user_id,
            }
        );
        return $response;
    } catch (error) {
    }
};



//
//
//
//
// company info
export const getInfoCompany = async () => {
    try {
        const $response = await axiosInstance.get(
            "/info-company",
            {
            }
        );
        return $response;
    } catch (error) {
    }
};

export const companyInfoSave = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/info-company",
            {
                name: dataPass.company_name,
                phone: dataPass.company_phone,
                instagram: dataPass.instagram,
                facebook: dataPass.facebook,
                whatsapp: dataPass.whatsapp,
                tiktok: dataPass.tiktok,
                roc: dataPass.company_roc,
                address: dataPass.company_address,
                email: dataPass.company_email,
                privacy: dataPass.privacy,
                tnc: dataPass.tnc,
                period_time: dataPass.period_time,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

//
//
//
//
// list of agent
export const listofagent = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/agent/list",
            {
                searchType: dataPass.type,
                value:  dataPass.value,
                role: dataPass.role,
                approved: dataPass.approved,
            }
        );
        return $response;
    } catch (error) {
    }
};


//
//
//
//
// auth

export const resetPassword = async (dataPass) => {
    try {
        const $response = await axiosInstance.post("/forgot-password", {
            email: dataPass.email,
        });
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const resetLinkPassword = async (dataPass) => {
    try {
        const $response = await axiosInstance.post("/reset-password", {
            token: dataPass.token,
            email: dataPass.email,
            password: dataPass.password,
            password_confirmation: dataPass.password_confirmation,
        });
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};


export const logingoogle = async (dataPass) => {
    try {
        const $response = await axiosInstance.post("/login-google", {
            token: dataPass.token,
        });
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const login = async (dataPass) => {
    try {
        const $response = await axiosInstance.post("/login", {
            email: dataPass.email,
            password: dataPass.password,
            remember: dataPass.remember,
        });
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const logout = async () => {
    try {
        const $response = await axiosInstance.post(
            "/logout",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

//
//
//
//
// point
export const saveConvertPoint = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/agent/convert-point",
            {
                agentId: dataPass.agentId,
                token: dataPass.token,
                point: dataPass.pointusage
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const getPointCompany = async () => {
    try {
        const $response = await axiosInstance.post(
            "/point-setting/detail",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const savePointCompany = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/point-setting/edit",
            {
                value: dataPass.value,
                equivalent: dataPass.equivalent
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const getActivityPoint = async () => {
    try {
        const $response = await axiosInstance.post(
            "collection-pages/list",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        // if (error.response.data.message === "Unauthorized") {
        //     localStorage.removeItem("token_expired");
        //     localStorage.removeItem("token_credential");
        //     routers.push("/auth/login");
        // }
    }
};

export const saveActivityPoint = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/collection-pages/update",
            {
                data: dataPass
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addPointUser = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/agent/update-point",
            {
                point: dataPass.point,
                agentId: dataPass.agentId
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        // if (error.response.data.message === "Unauthorized") {
        //     localStorage.removeItem("token_expired");
        //     localStorage.removeItem("token_credential");
        //     routers.push("/auth/login");
        // }
    }
};

//
//
//
//
// token
export const getTokenCompany = async () => {
    try {
        const $response = await axiosInstance.post(
            "/token-setting/detail",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const saveTokenCompany = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/token-setting/edit",
            {
                value: dataPass.value,
                price: dataPass.price
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

//
//
//
//
// profile
export const userProfile = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/profile",
            {
                userId: dataPass.user_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const updateProfile = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/profile/edit",
            {
                userId: dataPass.user_id,
                name: dataPass.name,
                phone: dataPass.phone,
                image: dataPass.image,
                bio: dataPass.bio,
                state: dataPass.state,
                city: dataPass.city,
                rendid: dataPass.rendid,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

//
//
//
//
// listing
export const getLandListing = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/list",
            {
                search: dataPass.search,
                city: dataPass.city,
                state: dataPass.state,
                type: dataPass.type,
                status: dataPass.status,
                category: dataPass.category,
                other: dataPass.other,
                size_amount: dataPass.size_amount,
                size: dataPass.size,
                price: dataPass.price,
                corporate: dataPass.corporate,
                user_id_listing: dataPass.user_id_listing,
                approval: dataPass.approval,
                check_approval: dataPass.check_approval,
                sold: dataPass.sold,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const soldLandListing = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/sold",
            {
                id_listing: dataPass.id_listing,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const deleteLandListing = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/delete",
            {
                id_listing: dataPass.id_listing,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const landReject = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/reject",
            {
                landId: dataPass.landId,
                message: dataPass.message,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const landApproval = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/approval",
            {
                landId: dataPass.landId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const saveLandListingCorp = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/create-corp",
            {
                public: formData.public,
                land_title: formData.land_title,
                land_price: formData.land_price,
                city: formData.city,
                state: formData.state,
                description: formData.description,
                usr_main_id: formData.usr_main_id,
                images: formData.images,
                video: formData.video,
                corporate: formData.corporate,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const updateLandListingCorp = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/update-corp",
            {
                public: formData.public,
                land_title: formData.land_title,
                land_price: formData.land_price,
                city: formData.city,
                state: formData.state,
                description: formData.description,
                usr_main_id: formData.usr_main_id,
                images: formData.images,
                video: formData.video,
                corporate: formData.corporate,
                landId: formData.landid,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};



export const updateLandListing = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/update",
            {
                public: formData.public,
                land_title: formData.land_title,
                land_topography: formData.land_topography,
                land_zoning: formData.land_zoning,
                land_category: formData.land_category,
                land_sales_type: formData.land_sales_type,
                land_type: formData.land_type,
                land_status: formData.land_status,
                size_of_land: formData.size_of_land,
                size_of_land_unit: formData.size_of_land_unit,
                land_price: formData.land_price,
                price_land_size_amount: formData.price_land_size_amount,
                price_land_size_unit: formData.price_land_size_unit,
                property_address: formData.property_address,
                property_postcode: formData.property_postcode,
                city: formData.city,
                property_mukim: formData.property_mukim,
                property_district: formData.property_district,
                state: formData.state,
                land_geolocation: formData.land_geolocation,
                description: formData.description,
                usr_main_id: formData.usr_main_id,
                images: formData.images,
                video: formData.video,
                corporate: formData.corporate,
                approved: formData.approved,
                landId: formData.landid,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};


export const saveLandListing = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/create",
            {
                public: formData.public,
                land_title: formData.land_title,
                land_topography: formData.land_topography,
                land_zoning: formData.land_zoning,
                land_category: formData.land_category,
                land_sales_type: formData.land_sales_type,
                land_type: formData.land_type,
                land_status: formData.land_status,
                size_of_land: formData.size_of_land,
                size_of_land_unit: formData.size_of_land_unit,
                land_price: formData.land_price,
                price_land_size_amount: formData.price_land_size_amount,
                price_land_size_unit: formData.price_land_size_unit,
                property_address: formData.property_address,
                property_postcode: formData.property_postcode,
                city: formData.city,
                property_mukim: formData.property_mukim,
                property_district: formData.property_district,
                state: formData.state,
                land_geolocation: formData.land_geolocation,
                description: formData.description,
                usr_main_id: formData.usr_main_id,
                images: formData.images,
                video: formData.video,
                corporate: formData.corporate,
                approved: formData.approved,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};


export const boostLandListing = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/boost-listing/land",
            {
                id: dataPass.id,
                usr_main_id: dataPass.usr_main_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const getPropertyListing = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/property-listing/list",
            {
                search: dataPass.search,
                city: dataPass.city,
                state: dataPass.state,
                area: dataPass.area,
                price: dataPass.price,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const savePropertyListing = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/property-listing/create",
            {
                usr_main_id : formData.usr_main_id, 
                video : formData.video, 
                images : formData.images, 
                images : formData.images,
                property_type : formData.property_type, 
                property_title : formData.property_title, 
                property_id : formData.property_id, 
                property_price : formData.property_price, 
                number_of_bedroom : formData.number_of_bedroom, 
                number_of_bathroom : formData.number_of_bathroom, 
                size_of_property : formData.size_of_property, 
                property_address : formData.property_address, 
                description : formData.description, 
                accessibility : formData.accessibility, 
                parking_type : formData.parking_type, 
                gim : formData.gim, 
                shop : formData.shop, 
                public : formData.public, 
                city : formData.city, 
                state : formData.state, 
                link : formData.link, 
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};



//
//
//
//
// package
export const listPackage = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/package/list",
            {
                search: dataPass.search,
                status: dataPass.status,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const packageAdd = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/package/create",
              formData, 
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const packageUpdate = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/package/update",
              formData, 
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const currentSubscription = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/subscription/list",
            {
                user_id: dataPass.user_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};


export const paymentToken = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/payment/top-up-token",
            {
                agentId: dataPass.user_id,
                amountToken: dataPass.amountToken,
                amountPrice: dataPass.amountPrice,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const paymentSubscribe = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/subscription/create",
            {
                agentId: dataPass.agentId,
                packageId: dataPass.packageId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const listLandDetails = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/land-listing/detail",
            {
                id_listing: dataPass.id_listing,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        // if (error.response.data.message === "Unauthorized") {
        //     localStorage.removeItem("token_expired");
        //     localStorage.removeItem("token_credential");
        //     routers.push("/auth/login");
        // }
    }
};

export const listPropertyDetails = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/property-listing/detail",
            {
                id_listing: dataPass.id_listing,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        // if (error.response.data.message === "Unauthorized") {
        //     localStorage.removeItem("token_expired");
        //     localStorage.removeItem("token_credential");
        //     routers.push("/auth/login");
        // }
    }
};

export const getDashboardAdmin = async () => {
    try {
        const $response = await axiosInstance.post(
            "/dashboard-hq",
            {
                
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};


//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// permission
export const listPermission = async () => {
    try {
        const $response = await axiosInstance.post(
            "/preference/access/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const permissionAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/access/add",
            {
                permissionName: dataPass.permissionName,
                permissionPrivilege: dataPass.permissionPrivilege,
                permissionStatus: dataPass.permissionStatus,
                permissionTrainerStatus: dataPass.permissionTrainerStatus,
                permissionSellerStatus: dataPass.permissionSellerStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const permissionEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/access/edit",
            {
                permissionId: dataPass.permissionId,
                permissionName: dataPass.permissionName,
                permissionPrivilege: dataPass.permissionPrivilege,
                permissionStatus: dataPass.permissionStatus,
                permissionTrainerStatus: dataPass.permissionTrainerStatus,
                permissionSellerStatus: dataPass.permissionSellerStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const permissionDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/access/delete",
            {
                permissionId: dataPass.permissionId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// user

export const updateStatus = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/change-status",
            {
                userId: dataPass.user_id,
                status: dataPass.status,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const canApproveAgent = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/change-approval",
            {
                userId: dataPass.userId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};



export const updatePassword = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/change-password",
            {
                userId: dataPass.user_id,
                password: dataPass.password,
                re_password: dataPass.re_password,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const listUser = async () => {
    try {
        const $response = await axiosInstance.post(
            "/user/list/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const userAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/register",
            {
                name: dataPass.name,
                email: dataPass.email,
                username: dataPass.username,
                phone: dataPass.phone,
                password: dataPass.password,
                password_confirmation: dataPass.cpassword,
                role: dataPass.role,
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const userEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/user/list/edit",
            {
                permissionUserMainId: dataPass.permissionUserMainId,
                permissionUserDetailId: dataPass.permissionUserDetailId,
                permissionUserLoginId: dataPass.permissionUserLoginId,
                permissionFirstName: dataPass.permissionFirstName,
                permissionLastName: dataPass.permissionLastName,
                permissionPassword: dataPass.permissionPassword,
                permissionEmail: dataPass.permissionEmail,
                permissionBrithday: dataPass.permissionBrithday,
                permissionContactNumber: dataPass.permissionContactNumber,
                permissionUserPermission: dataPass.permissionUserPermission,
                permissionUserStatus: dataPass.permissionUserStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const userDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/user/list/delete",
            {
                userId: dataPass.userId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// scheduler
export const listScheduler = async () => {
    try {
        const $response = await axiosInstance.post(
            "preference/scheduler/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const schedulerAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "preference/scheduler/add",
            {
                eventDetail: dataPass.eventDetail,
                eventLocation: dataPass.eventLocation,
                speakerId: dataPass.speakerId,
                eventTitle: dataPass.eventTitle,
                schedulerBrand: dataPass.schedulerBrand,
                schedulerName: dataPass.schedulerName,
                scheduleStatus: dataPass.scheduleStatus,
                eventTimeStart: dataPass.eventTimeStart,
                eventTimeEnd: dataPass.eventTimeEnd,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const schedulerEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "preference/scheduler/edit",
            {
                schedulerId: dataPass.schedulerId,
                eventDetail: dataPass.eventDetail,
                eventLocation: dataPass.eventLocation,
                speakerId: dataPass.speakerId,
                eventTitle: dataPass.eventTitle,
                schedulerBrand: dataPass.schedulerBrand,
                schedulerName: dataPass.schedulerName,
                scheduleStatus: dataPass.scheduleStatus,
                eventTimeStart: dataPass.eventTimeStart,
                eventTimeEnd: dataPass.eventTimeEnd,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const schedulerDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "preference/scheduler/delete",
            {
                schedulerId: dataPass.schedulerId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// package
// export const listPackage = async () => {
//     try {
//         const $response = await axiosInstance.post(
//             "/preference/package/view",
//             {},
//             {
//                 headers: {
//                     authorization: localStorage.getItem("token_credential"),
//                 },
//             }
//         );
//         return $response;
//     } catch (error) {
//         if (error.response.data.message === "Unauthorized") {
//             localStorage.removeItem("token_expired");
//             localStorage.removeItem("token_credential");
//             routers.push("/auth/login");
//         }
//     }
// };

// export const packageAdd = async (formData) => {
//     try {
//         const $response = await axiosInstance.post(
//             "/preference/package/add",
//             {
//                 photo1: formData.photo1,
//                 photo2: formData.photo2,
//                 packageName: formData.packageName,
//                 packagePrice: formData.packagePrice,
//                 packageDetail: formData.packageDetail,
//                 packageClass: formData.packageClass,
//                 packageIntakeStart: formData.packageIntakeStart,
//                 packageIntakeEnd: formData.packageIntakeEnd,
//                 packageStatus: formData.packageStatus,
//             },
//             {
//                 headers: {
//                     authorization: localStorage.getItem("token_credential"),
//                     "Content-Type": "multipart/form-data",
//                 },
//             }
//         );
//         return $response;
//     } catch (error) {
//         if (error.response.data.message === "Unauthorized") {
//             localStorage.removeItem("token_expired");
//             localStorage.removeItem("token_credential");
//             routers.push("/auth/login");
//         }
//     }
// };

export const packageEdit = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/package/edit",
            {
                photo1: formData.photo1,
                photo2: formData.photo2,
                packageId: formData.packageId,
                packageName: formData.packageName,
                packagePrice: formData.packagePrice,
                packageDetail: formData.packageDetail,
                packageClass: formData.packageClass,
                packageIntakeStart: formData.packageIntakeStart,
                packageIntakeEnd: formData.packageIntakeEnd,
                packageStatus: formData.packageStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const packageDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/package/delete",
            {
                packageId: dataPass.packageId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// class
export const listClass = async () => {
    try {
        const $response = await axiosInstance.post(
            "/preference/class/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const classAdd = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/class/add",
            {
                className: formData.className,
                classBadge: formData.classBadge,
                classCapacity: formData.classCapacity,
                classDetails: formData.classDetails,
                classDateStart: formData.classDateStart,
                classDateEnd: formData.classDateEnd,
                classTrainer: formData.classTrainer,
                classLocation: formData.classLocation,
                classStatus: formData.classStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const classEdit = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/class/edit",
            {
                classId: formData.classId,
                className: formData.className,
                classBadge: formData.classBadge,
                classCapacity: formData.classCapacity,
                classDetails: formData.classDetails,
                classDateStart: formData.classDateStart,
                classDateEnd: formData.classDateEnd,
                classTrainer: formData.classTrainer,
                classLocation: formData.classLocation,
                classStatus: formData.classStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const classDelete = async (classId) => {
    try {
        const $response = await axiosInstance.post(
            "/preference/class/delete",
            {
                classId: classId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// participant
export const listParticipant = async () => {
    try {
        const $response = await axiosInstance.post(
            "/prospect-management/list/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const participantAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/prospect-management/list/add",
            {
                pEventName: dataPass.pEventName,
                pFullName: dataPass.pFullName,
                pContactNumber: dataPass.pContactNumber,
                pSalesAverage: dataPass.pSalesAverage,
                pBusinessIndustry: dataPass.pBusinessIndustry,
                pBrandName: dataPass.pBrandName,
                pStatus: dataPass.pStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const participantEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/prospect-management/list/edit",
            {
                pId: dataPass.pId,
                pEventName: dataPass.pEventName,
                pFullName: dataPass.pFullName,
                pContactNumber: dataPass.pContactNumber,
                pSalesAverage: dataPass.pSalesAverage,
                pBusinessIndustry: dataPass.pBusinessIndustry,
                pBrandName: dataPass.pBrandName,
                pStatus: dataPass.pStatus,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const participantDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/prospect-management/list/delete",
            {
                pId: dataPass.pId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

//
//
//
//
//
//
//
//
//
// front desk
export const listAttendance = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/frontdesk/attendance/view",
            {
                classId: dataPass.classId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const attendanceAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/frontdesk/attendance/add",
            {
                classId: dataPass.classId,
                clientId: dataPass.clientId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const attendanceEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/frontdesk/attendance/edit",
            {
                attendId: dataPass.attendId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// client list
export const listClient = async () => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/list/view",
            {},
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const clientAdd = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/list/add",
            {
                participantId: dataPass.participantId,
                personal_detail: {
                    full_name: dataPass.personal_detail.full_name,
                    ic_number: dataPass.personal_detail.ic_number,
                    contact_number: dataPass.personal_detail.contact_number,
                },
                company: {
                    company_name: dataPass.company.company_name,
                    position: dataPass.company.position,
                    business_industry: dataPass.company.business_industry,
                    brand_name: dataPass.company.brand_name,
                    ssm_no: dataPass.company.ssm_no,
                    address_detail: dataPass.company.address_detail,
                    postcode: dataPass.company.postcode,
                    state: dataPass.company.state,
                    city: dataPass.company.city,
                    url_soc_twitter: dataPass.company.url_soc_twitter,
                    url_soc_fb: dataPass.company.url_soc_fb,
                    url_soc_instagram: dataPass.company.url_soc_instagram,
                    url_soc_tiktok: dataPass.company.url_soc_tiktok,
                },
                package: {
                    package_name: dataPass.package.package_name,
                    period_intake: dataPass.package.period_intake,
                    period_end: dataPass.package.period_end,
                    client_status: dataPass.package.client_status,
                    sales_person: dataPass.package.sales_person,
                    payment_status: dataPass.package.payment_status,
                    payment_amount: dataPass.package.payment_amount,
                    shirt_size: dataPass.package.shirt_size,
                    deposit_remarks: dataPass.package.deposit_remarks,
                    deposit_date: dataPass.package.deposit_date,
                    deposit_amount: dataPass.package.deposit_amount,
                },
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const clientEdit = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/list/edit",
            {
                clientId: dataPass.clientId,
                participantId: dataPass.participantId,
                personal_detail: {
                    full_name: dataPass.personal_detail.full_name,
                    ic_number: dataPass.personal_detail.ic_number,
                    contact_number: dataPass.personal_detail.contact_number,
                },
                company: {
                    company_name: dataPass.company.company_name,
                    position: dataPass.company.position,
                    business_industry: dataPass.company.business_industry,
                    brand_name: dataPass.company.brand_name,
                    ssm_no: dataPass.company.ssm_no,
                    address_detail: dataPass.company.address_detail,
                    postcode: dataPass.company.postcode,
                    state: dataPass.company.state,
                    city: dataPass.company.city,
                    url_soc_twitter: dataPass.company.url_soc_twitter,
                    url_soc_fb: dataPass.company.url_soc_fb,
                    url_soc_instagram: dataPass.company.url_soc_instagram,
                    url_soc_tiktok: dataPass.company.url_soc_tiktok,
                },
                package: {
                    package_name: dataPass.package.package_name,
                    period_intake: dataPass.package.period_intake,
                    period_end: dataPass.package.period_end,
                    client_status: dataPass.package.client_status,
                    sales_person: dataPass.package.sales_person,
                    payment_status: dataPass.package.payment_status,
                    payment_amount: dataPass.package.payment_amount,
                    shirt_size: dataPass.package.shirt_size,
                    deposit_remarks: dataPass.package.deposit_remarks,
                    deposit_date: dataPass.package.deposit_date,
                    deposit_amount: dataPass.package.deposit_amount,
                },
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const clientDelete = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/list/delete",
            {
                clientId: dataPass.clientId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
// Instant update status
// export const clientStatusUpdate = async (dataPass) => {
//     try {
//         const $response = await axiosInstance.post(
//             "/client-management/list/status",
//             {
//                 client_id: dataPass.clientId,
//                 client_status: dataPass.clientStatus,
//             },
//             {
//                 headers: {
//                     authorization: localStorage.getItem("token_credential"),
//                 },
//             }
//         );
//         return $response;
//     } catch (error) {
//         if (error.response.data.message === "Unauthorized") {
//             localStorage.removeItem("token_expired");
//             localStorage.removeItem("token_credential");
//             routers.push("/auth/login");
//         }
//     }
// };
//
//
//
//
//
//
//
//
//
// client profile
export const clientProfileDetail = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/dashboard/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const uploadProfilePic = async (formData) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/profile_pic/edit",
            {
                profile_pic: formData.profile_pic,
                client_id: formData.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const addPaymentJourney = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/payment_journey/add",
            {
                client_id: dataPass.client_id,
                payment_amount: dataPass.payment_amount,
                payment_date: dataPass.payment_date,
                payment_remarks: dataPass.payment_remarks,
                payment_iteration: dataPass.payment_iteration,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const editPaymentJourney = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/payment_journey/edit",
            {
                client_id: dataPass.client_id,
                payment_id: dataPass.id,
                payment_amount: dataPass.payment_amount,
                payment_date: dataPass.payment_date,
                payment_remarks: dataPass.payment_remarks
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
export const deletePaymentJourney = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/payment_journey/delete",
            {
                payment_id: dataPass.payment_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// sales tab client profile
export const clientProfileSales = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/sales/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addYear = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/sales/add",
            {
                client_id: dataPass.client_id,
                year: dataPass.year,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const editYear = async (dataPass) => {
    // console.log(dataPass);
    try {
        const $response = await axiosInstance.post(
            "/client-management/sales/edit",
            {
                monthSales: dataPass.months,
                year: dataPass.year,
                clientId: dataPass.clientId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const deleteYear = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/sales/delete",
            {
                clientId: dataPass.clientId,
                year: dataPass.year,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const clientProgress = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/progress/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addProgress = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/progress/add",
            {
                client_id: dataPass.client_id,
                current_issues: dataPass.current_issues,
                date: dataPass.date,
                role: dataPass.role,
                solution: dataPass.solution,
                speaker: dataPass.speaker,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const editProgress = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/progress/edit",
            {
                progress_id: dataPass.progress_id,
                client_id: dataPass.client_id,
                current_issues: dataPass.current_issues,
                date: dataPass.date,
                role: dataPass.role,
                solution: dataPass.solution,
                speaker: dataPass.speaker,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const deleteProgress = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/progress/delete",
            {
                progress_id: dataPass.progress_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const successStory = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/success/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addSuccessStory = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/success/add",
            {
                client_id: dataPass.client_id,
                date: dataPass.date,
                details: dataPass.details,
                title: dataPass.title,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const editSuccessStory = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/success/edit",
            {
                client_id: dataPass.client_id,
                date: dataPass.date,
                details: dataPass.details,
                story_id: dataPass.story_id,
                title: dataPass.title,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const deleteSuccessStory = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/success/delete",
            {
                client_id: dataPass.client_id,
                story_id: dataPass.story_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};


//
//
//
//
//
//
//
//
//
// photo tab client profile
export const successPhoto = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/photo/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addSuccessPhoto = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/photo/add",
            {
                category: dataPass.category,
                new_category: dataPass.new_category,
                photo: dataPass.photo,
                clientId: dataPass.clientId,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const deleteSuccessPhoto = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/photo/delete",
            {
                photoId: dataPass.photo_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// photo tab client profile
export const attendanceRecord = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/attendance/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
//
//
//
//
//
//
//
//
//
// photo tab client profile
export const btmValue = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/btm/view",
            {
                client_id: dataPass.client_id,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};

export const addBtmValue = async (dataPass) => {
    try {
        const $response = await axiosInstance.post(
            "/client-management/btm/edit",
            {
                client_id: dataPass.client_id,
                value1: dataPass.value1,
                value2: dataPass.value2,
                value3: dataPass.value3,
                value4: dataPass.value4,
                value5: dataPass.value5,
                value6: dataPass.value6,
                value7: dataPass.value7,
                value8: dataPass.value8,
            },
            {
                headers: {
                    authorization: localStorage.getItem("token_credential"),
                    "Content-Type": "multipart/form-data",
                },
            }
        );
        return $response;
    } catch (error) {
        if (error.response.data.message === "Unauthorized") {
            localStorage.removeItem("token_expired");
            localStorage.removeItem("token_credential");
            routers.push("/auth/login");
        }
    }
};
