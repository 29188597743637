<style scoped>
.sidebar-hiding {
    display: none;
}
@media (max-width: 992px) {
    .sidebar-hiding {
        display: block;
    }
}
</style>
<template>
    <div class="header-logo-wrapper sidebar-hiding col-auto p-0">
        <!-- <div class="logo-wrapper">
            <div>
                <img
                    class="img-fluid"
                    src="../../assets/images/logo/logo.png"
                    alt
                />
            </div>
        </div> -->
        <div class="toggle-sidebar" @click="toggle_sidebar">
            <vue-feather
                class="status_toggle middle sidebar-toggle"
                type="align-center"
                id="sidebar-toggle"
            >testamir</vue-feather>
        </div>
    </div>
</template>

<script>
export default {
    name: "Logo",
    methods: {
        toggle_sidebar() {
            this.$store.dispatch("menu/opensidebar");
        },
    },
};
</script>
