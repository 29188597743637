<style scoped>
.selected-type {
  border-bottom:2px solid #CA952B;
  width:100%;
}

.card-agent {
	background-color:#fefefe;
	border-radius: 10px;
}

.cagent-image {
	width: 30%;

}
.cagent-image>img {
	width: 80%;

}

.cagent-details {
	width: 50%;
	padding-left: 20px;
}

.cagent-ws {
	display: flex;
	width: 20%;
	padding-right: 5px;
	justify-content: flex-end;
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template> 
    <div class="padding-container">
		<div class="row card p-4">
			<div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
				<h5>
					Our Term and Condition
				</h5>
			</div>
			<div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
				<div :style="{ whiteSpace: 'pre-line' }">
					<p>{{ this.tnc }}</p>
				</div>
			</div>

		</div>
	</div>
</template>
<script>
import { toast } from "vue3-toastify";
import { 
	getInfoCompany,
} from "@/helper/apiservice/axios-http";

export default {
    components: {
    },
    data() {
        return {
			tnc: null,
        };
    },
    computed: {
    },
    mounted() {
		this.sendRequest();
    },
	beforeDestroy() {
	},
    created() {
    },
    methods: {
        //TOAST NOTIFICATION
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },

        async sendRequest() {
            try {
                await getInfoCompany().then((response) => {
                    if (response.data.success == true) {
                        this.tnc = response.data.data.tnc;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
