<style scoped>
.topup-window {
    border: 1px #CCCCCC solid;
    padding: 20px;
    margin: 20px;
    border-radius: 10px;
    cursor: pointer;
}
.topup-selected {
    background:#CA952B;
    color:#f3f4f6;
}
.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #CA952B;
}
.activeButtonPage {
    color: #CA952B;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.selected-type {
  border-bottom:2px solid #CA952B;
  width:100%;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}

/* file upload button */
input[type="file"]::file-selector-button {
    border-radius: 5px;
    /* padding: 0 16px; */
    height: 38px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    /* margin-right: 16px; */
    transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template>
    <div class="padding-container">
        <h4>My Profile</h4>
        <div class="row">
            <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4">
                <div class="card p-3" style="align-items: center;">
                    <img style="border-radius:50%; width: 150px; height: 150px;" :src="this.profile_image" />
                    <div class="py-1"></div>
                    <div class="d-flex">
                        <input  style="font-size: 0.9rem; max-width: 200px;" type="file" ref="fileInput1" single @change="handleFileChange1" />
                    </div>
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
                <div class="card p-3">
                    <!-- TAB  -->
                    <div >
                        <div class="d-flex" style="width: 100%;">
                            <div class="select-type" @click="changeTab('account')">
                                <div class="p-3" align="center" :class="{ 'selected-type': selectedTab === 'account' }" style="cursor:pointer">Account Details</div>
                            </div>
                            <div class="select-type" @click="changeTab('password')">
                                <div class="p-3" align="center" :class="{ 'selected-type': selectedTab === 'password' }" style="cursor:pointer">Change Password</div>
                            </div>
                        </div>
                    </div>

                    <!-- account -->
                    <div class="p-3 row" v-if="this.selectedTab === 'account'">
                        <div class="col-lg-12 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Bio</div>
                            <div style="display: flex; align-items: center;">
                                <textarea
                                    v-model="this.bio"
                                    class="form-control"
                                    style="height: 150px"
                                    type="text"
                                    placeholder="Tell about yourself.."
                                    required
                                ></textarea>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Name</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.user_name" class="form-control" type="text" placeholder="Ahmad"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Phone</div>
                            <div style="display: flex; align-items: center;">
                                <input v-model="this.phone" class="form-control" type="number" placeholder="0136654321"/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">State</div>
                            <div style="display: flex; align-items: center;">
                                <select class="form-select form-control-primary" name="select" v-model="this.state" @change="determineCity()">
                                    <option value="" disabled selected>Select State</option>
                                    <option value="Perlis">Perlis</option>
                                    <option value="Kedah">Kedah</option>
                                    <option value="Penang">Penang</option>
                                    <option value="Perak">Perak</option>
                                    <option value="Selangor">Selangor</option>
                                    <option value="Wp Kuala Lumpur">Wp Kuala Lumpur</option>
                                    <option value="Wp Putrajaya">Wp Putrajaya</option>
                                    <option value="Negeri Sembilan">Negeri Sembilan</option>
                                    <option value="Melaka">Melaka</option>
                                    <option value="Johor">Johor</option>
                                    <option value="Pahang">Pahang</option>
                                    <option value="Terengganu">Terengganu</option>
                                    <option value="Kelantan">Kelantan</option>
                                    <option value="Sarawak">Sarawak</option>
                                    <option value="Sabah">Sabah</option>
                                    <option value="Wp Labuan">Wp Labuan</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">City</div>
                            <div style="display: flex; align-items: center;">
                                <multiselect v-model="this.city" :options="landCities" 
                                :placeholder="this.state == ''? 'Select State First' : 'Select City'" 
                                style="font-weight: 400; font-size: 1rem !important;">
                                    <template #noResult>
                                        <div>No cities found. Please try a different search.</div>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                        <div class="col-lg-12 col-md-12" v-if="this.userRole == '2'">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <!-- detail agent di registration -->
                                    <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">REA / REN / PEA / PV / VE / PM ID</div>
                                    <div style="display: flex; align-items: center;">
                                        <input class="form-control" type="text" v-model="this.ren_id" placeholder="1234567"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Company</div>
                                    <div style="display: flex; align-items: center;">
                                        <input v-model="this.phone" class="form-control" type="number" placeholder="Company Name"/>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Email Address</div>
                            <div style="display: flex; align-items: center;">
                                <input class="form-control" type="text" v-model="this.email_address" placeholder="hello@swiftx.com.my" disabled/>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Username</div>
                            <div style="display: flex; align-items: center;">
                                <input class="form-control" type="text" v-model="this.username" placeholder="swiftx" disabled/>
                            </div>
                        </div>
                        <div class="py-3"></div>
                        <div class="" align="right">
                            <button class="btn" type="button" @click="updateProfileUser()" style=" width: 40%;color:#fefefe;background-color: #CA952B !important;border-color: #CA952B !important;">
                                Save
                            </button>
                        </div>
                    </div>

                    <!-- password -->
                    <div class="p-3 row" v-if="this.selectedTab === 'password'">
                        <div class="col-12">
                            <div>Please type a new password</div>
                        </div>
                        <div class="col-12">
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">New Password</div>
                            <div style="display: flex; align-items: center;">
                                <input @input="checkPass()" v-model="this.new_password" class="form-control" type="password" placeholder="********"/>
                            </div>
                            <div class="py-2" style="font-weight: 450;position:relative;top:5px;" align="left">Confirm New Password</div>
                            <div style="display: flex; align-items: center;">
                                <input @input="checkPass()" v-model="this.confirm_password" class="form-control" type="password" placeholder="********"/>
                            </div>
                        </div>
                        <div class="py-3"></div>
                        <div class="" align="right">
                            <button class="btn" type="button" @click="changePass()" style=" width: 40%;color:#fefefe;background-color: #CA952B !important;border-color: #CA952B !important;" :disabled="!this.matchPassword">
                                Update Password
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    allPostcodes,
    getStates,
    getCities,
    findCities,
    getPostcodes,
    findPostcode,
} from "malaysia-postcodes";
import {
    userProfile,
    updatePassword,
    updateProfile,
    verifyPassword
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import defaultAvatar from '../../../assets/images/user/avatar.png';
import Multiselect from "vue-multiselect";

export default {
    name: "User Profile",

    components: { Multiselect },

    data() {
        return {
            selectedTab: "account",
            selectedFiles1: null,
            user_name: null,
            profile_image: defaultAvatar,
            username: null,
            phone: null,
            email_address: null,
            ren_id: null,
            bio: null,
            state: "",
            city: "",
            userRole: localStorage.getItem('access'),
            
            verifyPassword: false,
            matchPassword: false,
            new_password: null,
            confirm_password: null,
            landCities: [],
        };
    },

    beforeMount() {
        this.loadProfileDetails();
    },

    mounted() { },

    created() { },

    computed: { },

    methods: {
        //TOAST NOTIFICATION
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
        determineCity() {
            this.city = "";
            this.landCities = getCities(this.state) || [];
        },

        handleFileChange1(event) {
            // Clear existing files
            this.selectedFiles1 = [];

            // Iterate through selected files
            Array.from(event.target.files).forEach((file) => {
                // Check if the file is an image
                if (file.type.startsWith("image/")) {
                    // Create a FileReader to read the image file
                    const reader = new FileReader();

                    // Define FileReader onload event to set image preview
                    reader.onload = () => {
                        this.selectedFiles1.push({
                            file: file,
                            preview: reader.result, // Set the image preview URL
                        });
                        this.profile_image = this.selectedFiles1[0].preview;
                    };

                    // Read the image file as a data URL
                    reader.readAsDataURL(file);
                }
            });
        },

        //FILTER CHANGE TAB
        changeTab(tabProgressName) {
            this.selectedTab = tabProgressName;
        },
        
        //CHECK PASSWORD TO MATCH EACH OTHER
        checkPass() {
            if (this.new_password == this.confirm_password) {
                this.matchPassword = true;
            } else {
                this.matchPassword = false;
            }
        },

        async loadProfileDetails() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                };
                await userProfile(dataPass).then((response) => {
                    if (response.data.success == true) {
                        if (response.data.data.image == null) {
                            this.profile_image = defaultAvatar;
                        } else {
                            this.profile_image = response.data.data.image;
                        }
                        this.user_name = response.data.data.name;
                        this.email_address = response.data.data.email;
                        this.ren_id = response.data.data.rendid;
                        this.phone = response.data.data.phone;
                        this.username = response.data.data.username;
                        this.bio = response.data.data.bio;
                        this.state = response.data.data.state;
                        this.city = response.data.data.city;
                        this.landCities = getCities(this.state);
                        localStorage.setItem("image", response.data.data.image);
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async verify() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                    password : this.old_password,
                };
                await verifyPassword(dataPass).then((response) => {
                    if (response.data.success == true) {
                        if (response.data.message == true) {
                            this.notificationSuccess("Password verified");
                            // display change password form
                            this.verifyPassword = true;
                            this.old_password = null;
                        } else {
                            //wrong password
                        }
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async updateProfileUser() {
            try {
                this.formData = new FormData();
                this.formData.user_id = localStorage.getItem("user_id");
                this.formData.name = this.user_name;
                this.formData.phone = this.phone;
                this.formData.image = (Array.isArray(this.selectedFiles1) && this.selectedFiles1.length > 0 && this.selectedFiles1[0].file)?  this.selectedFiles1[0].file : null;
                this.formData.bio = this.bio;
                this.formData.state = this.state;
                this.formData.city = this.city;
                this.formData.rendid = this.ren_id;
                

                await updateProfile(this.formData).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Profile Updated");
                        this.loadProfileDetails();
                        this.$refs.fileInput1.value = null;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async changePass() {
            try {
                const dataPass = {
                    user_id : localStorage.getItem("user_id"),
                    password : this.new_password,
                    re_password : this.confirm_password,
                };
                await updatePassword(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Password changed");
                        // display verify password back
                        this.verifyPassword = false;
                        this.new_password = null;
                        this.confirm_password = null;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>