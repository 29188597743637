<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 p-0">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo">
                                    <img height="500" class="img-fluid for-light" src="../assets/images/logo/logo_login.png" alt="loginpage" style="width:40%;"/>
                                    <img class="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="loginpage"/>
                                </a>
                            </div>
                            <div class="login-main">
                                <form class="theme-form">
                                    <div v-if="this.register== 'login'">
                                        <h4>Sign in to account</h4>
                                        <p>Enter your email & password to login</p>
                                        <div class="form-group">
                                            <label class="col-form-label">Email Address</label>
                                            <input class="form-control" type="email" required=""  placeholder="youremail@swfitx.com.my" v-model="user.email.value" />
                                            <span class="validate-error" v-if=" !user.email.value || !validEmail(user.email.value)">{{ user.email.errormsg }}</span>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Password</label>
                                            <div class="form-input position-relative">
                                                <input class="form-control" :type="passwordFieldType" name="login[password]"
                                                    required="" placeholder="*********" v-model="user.password.value" />
                                                <span class="validate-error" v-if=" user.password.value.length < 7 " >{{ user.password.errormsg }}</span >
                                                <div class="show-hide" @click=" togglePasswordVisibility " >
                                                    <span class="show">{{ showPasswordText }}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group mb-0">
                                            <div class="row">
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                                    <div class="checkbox p-0">
                                                        <input id="checkbox1" type="checkbox" v-model="user.remember.value" />
                                                        <label class="text-muted" for="checkbox1">Remember me</label>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex" style="align-items: center; justify-content: flex-end;">
                                                    <div class="text-muted" style="margin-top: 1px; cursor: pointer;" @click="openRegisterTab('forget')">Forgot password?</div>
                                                </div>
                                            </div>
                                            <div class="text-end mt-3">
                                                <button class="btn btn-primary btn-block w-100" type="submit" @click.prevent="loginSubmit" style="height: 40px;">Sign in</button>
                                            </div>
                                            <div class="text-end mt-3">
                                                <button class="btn btn-tertiary btn-block w-100" type="button" style="height: 40px; border: #CCC 1px solid;" @click="redirectToGoogle">
                                                    <img style="width: 22px;" src="../assets/images/logo/google.png" alt="logo google"/>
                                                    Sign in with Google
                                                </button>
                                            </div>
                                            <div class="text-end mt-3">
                                                <button class="btn btn-tertiary btn-block w-100" type="button" @click.prevent="redirect1()" >Back to Homepage</button>
                                            </div>

                                            <div class="pt-5" style="font-size:0.8rem;font-weight:400" align="center">
                                                Don't have an account? <div class="font-primary" style="cursor:pointer;" @click="openRegisterTab('register')">Register Here</div>
                                            </div>

                                        </div>   
                                    </div>
                                    <div v-else-if="this.register== 'register'">
                                        <h4>Registration</h4>
                                        <p>Fill in all the required details below to proceed</p>
                                        <div class="form-group">
                                            <select class="form-select form-control" name="select" required v-model="this.newRole">
                                                <option value="" disabled selected required > Select User </option>
                                                <option value="2">REA / REN / PEA / PV / VE / PM</option>
                                                <option value="4">LAND REPS / BROKER</option>
                                                <option value="3">INDIVIDUAL</option>
                                            </select>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Name</label>
                                            <input class="form-control" v-model="this.newName" type="text" required="" placeholder="Ahmad"/>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Username</label>
                                            <input class="form-control" v-model="this.newUsername" type="text" required="" placeholder="ahmad1131"/>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Phone</label>
                                            <input class="form-control" v-model="this.newPhone" type="text" required="" placeholder="0146678941"/>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Email</label>
                                            <input class="form-control" v-model="this.newEmail" type="text" required="" placeholder="ahmad@swiftx.com.my"/>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Password</label>
                                            <input class="form-control" v-model="this.newPassword" type="password" required="" placeholder="********"/>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Confirm Password</label>
                                            <input class="form-control" v-model="this.newConfirmPassword" type="password" required="" placeholder="********"/>
                                        </div>
                                        <div class="form-group mb-0">
                                            <div class="text-end mt-3">
                                                <button class="btn btn-primary btn-block w-100" type="button" @click="registerUser()">Confirm</button>
                                            </div>
                                        </div>
                                        <div class="pt-3" style="font-size:0.8rem;font-weight:400" align="center">
                                            Back to<span class="font-primary" style="cursor:pointer;" @click="openRegisterTab('login')"> Sign In</span>
                                        </div>
                                    </div>
                                     
                                    <div v-else-if="this.register== 'successRegister'">
                                        <h4>Registration Success!</h4>
                                        <p>You’re almost there! please verify your email. We sent an email to <span style="color: #000">{{ this.newEmail }}</span></p>
                                        
                                        <div class="pt-3" style="font-size:0.8rem;font-weight:400" align="center">
                                            Back to<span class="font-primary" style="cursor:pointer;" @click="openRegisterTab('login')"> Sign In</span>
                                        </div>
                                    </div>

                                    <div v-else-if="this.register== 'successCheckPassword'">
                                        <h4>Please Check Your Email!</h4>
                                        <p>You’re almost there! your password reset link is sent to your email.</p>
                                        
                                        <div class="pt-3" style="font-size:0.8rem;font-weight:400" align="center">
                                            Back to<span class="font-primary" style="cursor:pointer;" @click="openRegisterTab('login')"> Sign In</span>
                                        </div>
                                    </div>

                                    <div v-else-if="this.register== 'loginGoogle'">
                                        <h4>Please Wait!</h4>
                                        <p>While we verify your credential.</p>
                                        <div class="loader-box">
                                            <div class="loader-3"></div>
                                        </div>
                                        
                                        <div class="pt-3" style="font-size:0.8rem;font-weight:400" align="center">
                                            Back to<span class="font-primary" style="cursor:pointer;" @click="openRegisterTab('login')"> Sign In</span>
                                        </div>
                                    </div>

                                    <div v-else-if="this.register== 'forget'">
                                        <h4>Forget Password</h4>
                                        <p>Enter your email to reset your password!</p>
                                        <div class="form-group">
                                            <label class="col-form-label">Email</label>
                                            <input class="form-control" v-model="this.resetEmail" type="text" required="" placeholder="Ahmad@swiftx.com.my"/>
                                        </div>
                                        <div class="form-group mb-0">
                                            <div class="text-end mt-3">
                                                <button class="btn btn-primary btn-block w-100" type="button" @click="resetPassword()">Reset Password</button>
                                            </div>
                                        </div>
                                        <div class="pt-3" style="font-size:0.8rem;font-weight:400" align="center">
                                            Back to<span class="font-primary" style="cursor:pointer;" @click="openRegisterTab('login')"> Sign In</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { userAdd, login, resetPassword,logingoogle } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "login",

    data() {
        return {
            showPassword: false,
            result: {
                email: "",
                password: "",
            },
            user: {
                remember: {
                    value: false,
                    errormsg: "",
                },
                email: {
                    value: "",
                    errormsg: "",
                },
                password: {
                    value: "",
                    errormsg: "",
                },
            },
            newName: null,
            newPhone: null,
            newEmail: null,
            register: 'login',
            newUsername: null,
            newPassword: null,
            newConfirmPassword: null,
            newRole: "",
            resetEmail: null,
        };
    },

    created() {},

    computed: {
        passwordFieldType() {
            return this.showPassword ? "text" : "password";
        },
        showPasswordText() {
            return this.showPassword ? "Hide" : "Show";
        },
    },

    // ----------------- google login -----------------
    mounted() {
        // Check if there's a token in the URL from the backend redirect
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get("token");

        if (token) {
            this.handleGoogleCallback(token);
        }
    },

    methods: {
        // ----------------- google login -----------------
        async handleGoogleCallback(token) {
            this.register = 'loginGoogle';
            const dataPass = {
                token: token,
            };

            try {
                await logingoogle(dataPass).then((response) => {
                    if (response.data.success == true) {
                        // STORE USER CREDENTIALS GLOBALLY IN LOCAL STORAGE
                        localStorage.setItem( "token_credential", response.data.data.token );
                        localStorage.setItem( "token_expired",  response.data.data.expired );
                        localStorage.setItem("details", JSON.stringify(response.data.data.details));
                        localStorage.setItem("user_id", response.data.data.id);
                        localStorage.setItem("role", response.data.data.email);
                        localStorage.setItem("username", response.data.data.detail.username);
                        localStorage.setItem("image", response.data.data.detail.image);
                        localStorage.setItem("access", response.data.data.role);
                        this.$store.dispatch("menu/refreshMenuItems");
                        this.$router.push("/dashboard/stats");
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        redirectToGoogle() {
            window.location.href = "https://api-landcartel.swiftx.my/auth/google";
        },






        redirect1() {
            this.$router.push("/web/homepage");
        },

        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        validEmail: function (email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },

        async loginSubmit() {
            if (
                !this.user.password.value ||
                this.user.password.value.length < 7
            ) {
                this.user.password.errormsg = "min length 7";
            } else {
                this.user.password.errormsg = "";
            }

            if (!this.user.email.value) {
                this.user.email.errormsg = "Invalid email address";
            } else if (!this.validEmail(this.user.email.value)) {
                this.user.email.errormsg = "Valid email required.";
            } else {
                this.user.email.errormsg = "";
            }

            if (!this.user.email.errormsg && !this.user.password.errormsg) {
                const dataPass = {
                    email: this.user.email.value,
                    password: this.user.password.value,
                    remember: this.user.remember.value,
                };

                try {
                    await login(dataPass).then((response) => {
                        if (response.data.success == true) {
                            // STORE USER CREDENTIALS GLOBALLY IN LOCAL STORAGE
                            localStorage.setItem( "token_credential", response.data.data.token );
                            localStorage.setItem( "token_expired",  response.data.data.expired );
                            localStorage.setItem("details", JSON.stringify(response.data.data.details));
                            localStorage.setItem("user_id", response.data.data.id);
                            localStorage.setItem("role", response.data.data.email);
                            localStorage.setItem("username", response.data.data.detail.username);
                            localStorage.setItem("image", response.data.data.detail.image);
                            localStorage.setItem("access", response.data.data.role);
                            this.$store.dispatch("menu/refreshMenuItems");
                            this.$router.push("/dashboard/stats");
                        } else {
                            this.notificationError(response.data.message);
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },

        openRegisterTab(state) {
            this.register = state;
        },

        async registerUser() {
            try {
                const dataPass = {
                    name : this.newName,
                    email : this.newEmail,
                    phone : this.newPhone,
                    username : this.newUsername,
                    password : this.newPassword,
                    cpassword : this.newConfirmPassword,
                    role : this.newRole,
                };
                await userAdd(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.register = 'successRegister';
                        this.newName = null;
                        this.newPhone = null;
                        this.newUsername = null;
                        this.newPassword = null;
                        this.newConfirmPassword = null;
                        this.newRole = "";
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async resetPassword() {
            try {
                const dataPass = {
                    email : this.resetEmail,
                };
                await resetPassword(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.register = 'successCheckPassword';
                        this.resetEmail = null;
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        }
    },
};
</script>
