<style scoped>
.toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.toggle-label {
  cursor: pointer;
  width: 50px;
  height: 25px;
  background: grey;
  display: inline-block;
  border-radius: 25px;
  position: relative;
  transition: background-color 0.3s ease-in-out;
}

.toggle-label::after {
  content: '';
  position: absolute;
  top: 3px;
  left: 3px;
  width: 19px;
  height: 19px;
  background: white;
  border-radius: 50%;
  transition: 0.3s;
}

input:checked + .toggle-label {
  background: #4caf50;
}

input:checked + .toggle-label::after {
  left: calc(100% - 3px);
  transform: translateX(-100%);
}


.inactivePage {
    color: #cccccc;
}
.activePage {
    color: #04318c;
}
.activeButtonPage {
    color: #04318c;
}
.inactiveButtonPage {
    color: #cccccc;
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.selected {
    color: #04318c;
    font-size: 1rem;
    font-weight: 500;
    border-bottom: 3px solid #04318c;
    box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}
.search-table {
    table-layout: fixed;
    margin: 0px auto 0px auto;
}
.search-table,
td,
th {
    border-collapse: collapse;
}
th {
    padding: 10px 7px;
    font-size: 15px;
    color: #52526c;
    background: #f5f6f9;
}
td {
    padding: 5px 10px;
    height: 35px;
}

.search-table-outter {
    overflow-x: scroll;
}

/* file upload button */
input[type="file"]::file-selector-button {
    border-radius: 4px;
    padding: 0 16px;
    height: 40px;
    cursor: pointer;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    margin-right: 16px;
    transition: background-color 200ms;
}

/* file upload button hover state */
input[type="file"]::file-selector-button:hover {
    background-color: #f3f4f6;
}

/* file upload button active state */
input[type="file"]::file-selector-button:active {
    background-color: #e5e7eb;
}
.upload-container {
    border: 2px dashed #ccc;
    border-radius: 8px;
    text-align: center;
    padding: 20px;
    position: relative;
    background-color: #f5f5f5;
}

.upload-container:hover {
    background-color: #ebebeb;
}


.upload-container img {
    max-height: 100px;
    margin: 10px 0;
    object-fit: contain;
}

.upload-container input[type="file"] {
    display: none;
}

.upload-label {
    display: block;
    cursor: pointer;
    color: #666;
    font-size: 1rem;
    margin-top: 10px;
}
.package-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

.package-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 15px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.package-image {
    width: 80px;
    height: 80px;
    border-radius: 10px;
    object-fit: cover;
    background-color: #f5f5f5;
}

.package-details {
    flex-grow: 1;
    padding: 0 20px;
}

.package-title {
    font-size: 1.2rem;
    font-weight: bold;
    color: #04318c;
}

.package-description {
    font-size: 0.9rem;
    color: #666;
    margin-top: 8px;
}

.package-price {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.package-actions {
    display: flex;
    gap: 15px;
}

.package-actions svg {
    cursor: pointer;
    transition: color 0.3s ease;
}

.package-actions svg:hover {
    color: #04318c;
}



.padding-container {
    padding: 1.5rem;
}
.required {
    color: red;
    margin-left: 2px;
    font-weight: bold;
}
.btn-size-add {
    width: 150px;
}
@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
    .btn-size-add {
        min-width: 100%;
    }
}
</style>
<style>
.multiselect__placeholder {
    font-size: 1rem !important;
    color: #c9c9c9 !important;
    margin-bottom: 0px !important;
    padding-top: 0px !important;
}
.multiselect__select {
	padding: 0px !important;
}
.multiselect__tags {
    min-height: 38px !important;
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}
.multiselect__select::before {
    top: 60% !important;
}
</style>

<template>
    <!-- setup coupon -> bind to package -->
    <!-- agent listing own landing page -->
    <!-- news -->
    <!-- percentage 1% for listing based on sales price -->
    <div class="padding-container">
        <!-- main page -->
        <div class="card p-4">
            <div class="row" style="justify-content: space-between">
                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex" style="align-items: center;">
                    <span style="font-size: 1.2rem; font-weight: 500;">Package List</span>
                </div>

                <div class="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex" style="justify-content: flex-end;" >
                    <button @click="clearModalPackage()" class="p-2 rounded d-flex btn-size-add" 
                    data-bs-toggle="modal" data-bs-target="#addPackage"
                    style="background-color: #04318c; color: #fefefe; justify-content: center; align-items: center;">
                        <div style="padding-right: 10px; margin-top: 3px">
                            <svg xmlns="http://www.w3.org/2000/svg" style="width: 13px;" fill="currentColor" class="bi bi-plus-square" viewBox="0 0 16 16">
                                <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                            </svg>
                        </div>
                        <div>Add Package</div>
                    </button>
                </div>

                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 pt-3 d-flex">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Search Title or Description..." aria-label="Search" aria-describedby="button-addon2" v-model="this.searchListing" style="border-top-right-radius: 0px; border-bottom-right-radius: 0px;" />
                    </div>
                    <div>
                        <button class="btn btn-outline-primary" type="button" id="button-addon2" @click="performSearch()" style="height:100%; border-top-left-radius: 0px; border-bottom-left-radius: 0px;">
                            <div style="margin-top:5px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
                                </svg>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="this.tableloader == false">
            <div v-if="this.clientTableDataResult.length != 0">
                <div class="d-flex" style="justify-content: center" v-for="(item, index) in clientTableDataResult" :key="index">
                    <div class="card p-0 listing-card" style="width: 100%;">
                        <div class="row">
                            <div class="col-4 col-sm-4 col-md-4 col-lg-4 col-xl-2 d-flex" style="justify-content: center; align-items: center;">
                                <img :src="item.photo" alt="Package Image" style="width: 80px; border-radius: 50%;"class="package-image">
                            </div>
                            <div class="col-8 col-sm-8 col-md-8 col-lg-8 col-xl-10">
                                <div class="p-3">
                                    <div class="d-flex" style="flex-direction: column; justify-content: space-between; height: 100%; width: 100%">
                                        <div>
                                            <div style="font-size: 1rem; font-weight: 450; overflow-wrap: break-word;">{{ item.title }}</div>
                                            <div class="pt-2" style="font-size: 0.8rem; font-weight: 300; overflow-wrap: break-word;">Number of Listing: {{ item.number_listing }}</div>
                                            <div class="pt-2" style="font-size: 0.8rem; font-weight: 300; overflow-wrap: break-word;">Video: {{ item.video == "1"? "enable" : "disable" }}</div>
                                            <div class="pt-2" style="font-size: 0.8rem; font-weight: 450; overflow-wrap: break-word;">
                                                Price: RM {{ parseFloat(item.price_subs).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} / {{ item.period_sub }} month {{ item.disc_sub == "0.00"? "" : "@ Discount " + item.disc_sub + "%" }}
                                            </div>
                                        </div>
                                        <div class="d-flex button-position" style="flex-wrap: wrap">
                                            <div class="pt-3 pb-2 pe-2">
                                                <button data-bs-toggle="modal" data-bs-target="#addPackage" class="btn btn-tertiary btn-block p-2" style="min-width: 100px;  height:100%; border: #ccc solid 1px" @click="intializeEditPackage(item)">Edit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="px-4 d-flex" style="flex-direction: column; align-items: center; width: 100%;">
                <div>
                    <img style="border-radius: 5px; width: 300px;" alt="" src="../../assets/images/no_data_image.png" />
                </div>
                <div style="text-align: center;">
                    <h6>No result found!</h6>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="d-flex" style="flex-direction: column; justify-content: center;">
                <h6 class="mb-0 text-center">Please Wait...</h6>
                <div class="loader-box">
                    <div class="loader-3"></div>
                </div>
            </div>
        </div>

    </div>

    <!-- pop up add/edit package -->
    <div class="modal fade modal-bookmark" id="addPackage" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span v-if="this.packageId === null">Create</span><span v-else>Edit</span> Package
                    </h5>
                    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div style="max-height: 60vh; overflow: scroll; overflow-x: hidden;">
                    <div class="modal-body row" style="justify-content: space-between">
                        <div class="py-2 col-12" style="font-size: 1rem">
                            <div style="font-weight: 450">Upload Image<span class="required">*</span></div>
                            <div class="upload-container">
                                <img v-if="packageImgPreview" :src="packageImgPreview" alt="Uploaded Image" style="max-width: 100%; height: auto;">
                                <input type="file" id="file-upload" ref="fileInput" @change="handleFileChange">
                                <label for="file-upload" class="upload-label">Insert images</label>
                            </div>      
                        </div>
                            
                        <div class="py-2 col-12" style="font-size: 1rem" >
                            <div style="font-weight: 450">Package Title<span class="required">*</span></div>
                            <input v-model="this.packageName" class="form-control" type="text" placeholder="Package Title" required />
                        </div>

                        <div class="py-2 col-12" style="font-size: 1rem">
                            <div style="font-weight: 450">Description<span class="required">*</span></div>
                            <textarea v-model="this.packageDetail" class="form-control" style="height: 150px" type="text" placeholder="Detail about the package.." required></textarea>
                        </div>
                        <div class="py-2 col-12" style="font-size: 1rem" >
                            <div style="font-weight: 450">Number of Public Listing<span class="required">*</span></div>
                            <div>
                                <label class="sr-only" for="inlineFormInputGroup">Number of Public Listing</label>
                                <div class="input-group mb-2">
                                    <input v-model="this.packageNumberListing" type="text" class="form-control" id="inlineFormInputGroup" placeholder="Number of Listing" required >
                                    <div class="input-group-prepend">
                                        <div class="input-group-text" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">Listing</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="py-2 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="font-size: 1rem">
                            <div style="font-weight: 450">Price Subscription<span class="required">*</span></div>
                            <div>
                                <label class="sr-only" for="inlineFormInputGroup">RM</label>
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text" style="height: 38px; font-size: 0.9rem; border-top-right-radius: 0px; border-bottom-right-radius: 0px;">RM</div>
                                    </div>
                                    <input v-model="this.packagePrice" type="text" class="form-control" id="inlineFormInputGroup" placeholder="Price Subscription" required >
                                </div>
                            </div>
                        </div>
                        <div class="py-2 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="font-size: 1rem">
                            <div style="font-weight: 450">Discount Percentage<span class="required">*</span></div>
                            <div>
                                <label class="sr-only" for="inlineFormInputGroup">Discount Percentage</label>
                                <div class="input-group mb-2">
                                    <input v-model="this.packageDisc" type="text" class="form-control" id="inlineFormInputGroup" placeholder="30" required >
                                    <div class="input-group-prepend">
                                        <div class="input-group-text" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">%</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="py-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" style="font-size: 1rem">
                            <div style="font-weight: 450">Period Subscription<span class="required">*</span></div>
                            <div>
                                <label class="sr-only" for="inlineFormInputGroup">Period Subscription</label>
                                <div class="input-group mb-2">

                                    <multiselect style="width: 1%; flex: 1 1 auto;" v-model="periodSubscription" :options="periodOptions" placeholder="Select Period">
                                        <template #noResult>
                                            <div>No subscription period found!</div>
                                        </template>
                                    </multiselect>
                                    <div class="input-group-prepend">
                                        <div class="input-group-text" style="border-top-left-radius: 0px; border-bottom-left-radius: 0px;">Month</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="py-2 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="font-size: 1rem">
                            <div style="font-weight: 450">Status<span class="required">*</span></div>
                            <div>
                                <multiselect  v-model="periodStatus" :options="statusOptions" placeholder="Select Status" :custom-label="formatOption">
                                    <template #noResult>
                                        <div>No status found!</div>
                                    </template>
                                </multiselect>
                            </div>
                        </div>
                        <div class="py-2 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6" style="font-size: 1rem" >
                            <div style="font-weight: 450">Video<span class="required">*</span></div>
                            <div class="toggle-container">
                                <input type="checkbox" id="toggle-switch2" v-model="this.packageVideo" />
                                <label for="toggle-switch2" class="toggle-label"></label>
                                <p style="margin-top: 10px;">{{ this.packageVideo ? 'On' : 'Off' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row p-3">
                    <div class="col-12 d-flex" style="justify-content: flex-end">
                        <div v-if="this.packageId !== null">
                            <button @click="deletePackage(this.packageId)" data-bs-dismiss="modal" class="btn btn-danger" type="button" style="width: 100%">
                                Delete
                            </button>
                        </div>
                        <div style="padding-right: 20px"></div>
                        <div>
                            <button @click="savePackage()" data-bs-dismiss="modal" class="btn btn-color" type="button" style="width: 100%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                <span v-if="this.packageId === null">Add</span><span v-else>Update</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    listPackage,
    packageAdd,
    packageUpdate,
    packageDelete,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import Multiselect from "vue-multiselect";

export default {
    name: "Setting Package",

    components: { Multiselect },

    data() {
        return {
            clientTableDataResult: null,
            tableloader: true,
            formData: [],

            packageId: null,
            packageImg: null,
            packageImgPreview: null,
            packageName: null,
            packageDetail: null,
            packageNumberListing: null,
            packagePrice: null,
            packageDisc: null,
            periodSubscription: "",
            packageVideo: false,
            periodStatus: "",

            // dropdown
            statusOptions: ["active", "suspend"],
            periodOptions: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
        };
    },

    beforeMount() {
        this.loadListPackage();
    },

    mounted() {},

    created() {},

    computed: {},

    methods: {
        formatOption(text) {
            return text.charAt(0).toUpperCase() + text.slice(1);
        },
        handleFileChange(event) {
            const file = event.target.files[0]; // Get the first selected file
            if (file && file.type.startsWith('image/')) { // Check if the file is an image
                const reader = new FileReader(); // Create a FileReader instance

                reader.onload = (e) => {
                    // console.log("File detected:", file); // Log the file for debugging
                    // console.log("Generated preview URL:", e.target.result); // Log the preview URL for debugging

                    // Update the packageImg and packageImgPreview data properties
                    this.packageImg = file; 
                    this.packageImgPreview = e.target.result; 

                    // Optional: trigger UI updates manually if needed, e.g., in Vue.js or React
                    this.$forceUpdate?.(); // If using Vue.js, force the component to update
                };

                // Read the file as a Data URL for the preview
                reader.readAsDataURL(file);
            } else {
                // console.log("No valid image file selected");
                // Clear previous preview if no valid file is selected
                this.packageImg = null;
                this.packageImgPreview = null;
            }
        },

        clearModalPackage() {
            this.packageId = null;
            this.packageImg = null;
            this.packageImgPreview = null;
            this.packageName = null;
            this.packageDetail = null;
            this.packageNumberListing = null;
            this.packagePrice = null;
            this.packageDisc = null;
            this.periodSubscription = "";
            this.packageVideo = false;
            this.periodStatus = "";
            this.$refs.fileInput.value = null;
        },

        intializeEditPackage(dataTable) {
            this.packageId = dataTable.id;
            // this.packageImg = null;
            this.packageImgPreview = dataTable.photo;
            this.packageName = dataTable.title;
            this.packageDetail = dataTable.desc;
            this.packageNumberListing = dataTable.number_listing;
            this.packagePrice = dataTable.price_subs;
            this.packageDisc = dataTable.disc_sub;
            this.periodSubscription = dataTable.period_sub;
            this.periodStatus = dataTable.status;
            this.packageVideo = dataTable.video == 1? true: false;
        },

        // api
        async performSearch() {
            this.loadListPackage();
        },

        async loadListPackage() {
            this.tableloader = true;

            const dataPass = {
                search: this.searchListing,
                status: 'active',
            };
            try {
                await listPackage(dataPass).then((response) => {
                    if (response.data.success === true) {
                        this.clientTableDataResult = response.data.data || [];
                        this.tableloader = false;
                        this.currentPage = 1;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async savePackage() {
            if (this.packageId === null) {
                this.formData = new FormData();

                // Pastikan nilai ada sebelum ditambahkan ke FormData
                if (this.packageName) this.formData.append("title", this.packageName);
                if (this.packageImg) this.formData.append("photo", this.packageImg);
                if (this.packagePrice) this.formData.append("price_subs", this.packagePrice);
                if (this.packageDetail) this.formData.append("description", this.packageDetail);
                if (this.packageNumberListing) this.formData.append("number_listing", this.packageNumberListing);
                if (this.periodSubscription) this.formData.append("period_sub", this.periodSubscription);
                if (this.packageDisc) this.formData.append("disc_sub", this.packageDisc);
                if (this.periodStatus) this.formData.append("status", this.periodStatus);
        
                // Tambahkan status land and building dan video berdasarkan checkbox
                this.formData.append("video", this.packageVideo ? 1 : 0);

                // Debugging untuk melihat apakah FormData berhasil terisi
                for (var pair of this.formData.entries()) {
                    // console.log(pair[0]+ ', ' + pair[1]); 
                }

                try {
                    await packageAdd(this.formData).then((response) => {
                        if (response.data.success == true) {
                            this.clearModalPackage();
                            this.loadListPackage();
                        } else {
                            this.notificationError(response.data.message);
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            
            } else {

                this.formData = new FormData();

                // Pastikan nilai ada sebelum ditambahkan ke FormData
                if (this.packageId) this.formData.append("id", this.packageId);
                if (this.packageName) this.formData.append("title", this.packageName);
                if (this.packageImg) this.formData.append("photo", this.packageImg);
                if (this.packagePrice) this.formData.append("price_subs", this.packagePrice);
                if (this.packageDetail) this.formData.append("description", this.packageDetail);
                if (this.packageNumberListing) this.formData.append("number_listing", this.packageNumberListing);
                if (this.periodSubscription) this.formData.append("period_sub", this.periodSubscription);
                if (this.packageDisc) this.formData.append("disc_sub", this.packageDisc);
                if (this.periodStatus) this.formData.append("status", this.periodStatus);
        
                // Tambahkan status land and building dan video berdasarkan checkbox
                this.formData.append("video", this.packageVideo ? 1 : 0);

                // Debugging untuk melihat apakah FormData berhasil terisi
                for (var pair of this.formData.entries()) {
                    // console.log(pair[0]+ ', ' + pair[1]); 
                }

                try {
                    await packageUpdate(this.formData).then((response) => {
                        if (response.data.success == true) {
                            this.clearModalPackage();
                            this.loadListPackage();
                        } else {
                            this.notificationError(response.data.message);
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
        },

        async deletePackage(id) {
            const dataPass = {
                packageId: id,
            };

            try {
                await packageDelete(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.clearModalPackage();
                        this.loadListPackage();
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },




        // uiux
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },

        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },

        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
    },
};
</script>
