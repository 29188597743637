<template>
	<div @click="route1()" style="cursor: pointer;">
		<img
			class="img-fluid for-light"
			src="../../assets/images/logo/logo-header-sidebar.png"
			alt=""
			/>
		<img
			class="img-fluid for-dark"
			src="../../assets/images/logo/logo-header-sidebar.png"
			alt=""
			/>
	</div>
</template>

<script>
export default {
	name: 'Logo',

	methods: {
		route1() {
			this.$router.push("/web/homepage");
		},
	},
}
</script>
