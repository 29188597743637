<style scoped>
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template>
    <div class="padding-container">
        <!-- invoice -->

        <div class="p-4 card">
            <div class="row" style="justify-content: space-between">
                <div class="col-12 col-sm-5 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex" style="align-items: center;">
                    <div style="cursor:pointer; padding-top: 8px;" @click="redirectPage()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#52526C" class="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
                        </svg>
                    </div>
                    <div class="ps-4" style="font-size: 1.2rem; font-weight: 500;">View Invoice</div>
                </div>
                
                <div class="col-12 col-sm-7 col-md-6 col-lg-6 col-xl-6 col-xxl-6 d-flex" style="justify-content: flex-end">
                    <button class="btn btn btn-primary me-2" type="button" @click="downloadInvoice()">Download</button>
                </div>
            </div>
        </div>

        <div id="print">
            <div class="row">
                <div class="col-sm-12">
                    <div class="card">
                        <div class="card-body">
                            <div>
                                <div>
                                    <div class="row invo-header">
                                        <div class="col-sm-6">
                                            <div class="d-flex align-items-center">
                                                <div class="d-flex-left"><img class="d-flex-object img-60"
                                                        src="@/assets/images/logo/logo-header.png" alt=""></div>
                                                <div class="flex-grow-1 m-l-20">
                                                    <h4 class="d-flex-headin">{{ this.company_name }}</h4>
                                                    <p>{{ this.company_phone }}<br><span class="digits">{{ this.email }}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <div class="text-md-end text-xs-center">
                                                <h3>Invoice #<span class="digits counter">{{ this.$route.query.invoice }}</span></h3>
                                                <p>Issued: May<span class="digits"> 27, 2023</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row invo-profile">
                                    <div class="col-xl-4">
                                        <div class="invo-profile-left">
                                            <div class="d-flex">
                                                <div class="d-flex-left"><img class="d-flex-object rounded-circle img-60"
                                                        src="@/assets/images/user/1.jpg" alt=""></div>
                                                <div class="flex-grow-1 ms-3">
                                                    <h4 class="d-flex-heading">Johan Deo</h4>
                                                    <p>JohanDeo@gmail.com<br /><span class="digits">555-555-5555</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-8">
                                        <div class="invo-profile-right">
                                            <div id="project" class="text-xl-end">
                                                <h6>Project Description</h6>
                                                <p>You're only as good as your last collection, which is an enormous pressure.
                                                    Jeans represent democracy in fashion. Fashion is about dressing according to
                                                    what's fashionable.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div class="table-responsive invoice-table" id="table">
                                        <table class="table table-bordered table-striped">
                                            <tbody>
                                                <tr>
                                                    <td class="item">
                                                        <h6 class="p-2 mb-0">Item Description</h6>
                                                    </td>
                                                    <td class="Hours">
                                                        <h6 class="p-2 mb-0">Hours</h6>
                                                    </td>
                                                    <td class="Rate">
                                                        <h6 class="p-2 mb-0">Rate</h6>
                                                    </td>
                                                    <td class="subtotal">
                                                        <h6 class="p-2 mb-0">Sub-total</h6>
                                                    </td>
                                                </tr>
                                                <tr v-for="invoice  in invoices" :key="invoice">
                                                    <td>
                                                        <label>{{ invoice.label }}</label>
                                                        <p class="m-0">{{ invoice.decp }}</p>
                                                    </td>
                                                    <td>
                                                        <p class="itemtext digits">{{invoice.hours }}</p>
                                                    </td>
                                                    <td>
                                                        <p class="itemtext digits">{{ invoice.rate }}</p>
                                                    </td>
                                                    <td>
                                                        <p class="itemtext digits">{{ invoice.subtotal }}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p class="itemtext"></p>
                                                    </td>
                                                    <td>
                                                        <p class="m-0">HST</p>
                                                    </td>
                                                    <td>
                                                        <p class="m-0 digits">13%</p>
                                                    </td>
                                                    <td>
                                                        <p class="m-0 digits">$419.25</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td></td>
                                                    <td></td>
                                                    <td class="Rate">
                                                        <h6 class="mb-0 p-2">Total</h6>
                                                    </td>
                                                    <td class="payment digits">
                                                        <h6 class="mb-0 p-2">$3,644.25</h6>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div class="row mt-3">
                                        <div class="col-md-8">
                                            <div>
                                                <p class="legal"><strong>Thank you for your business!</strong>Payment is
                                                    expected within 31 days; please process this invoice within that time. There
                                                    will be a 5% interest charge per month on late invoices.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    getInfoCompany,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";
import html2pdf from 'html2pdf.js';

export default {
    name: "Invoice",

    components: { },

    data() {
        return {
            tableloader: true,
            company_name: null,
            company_phone: null,
            email: null,
            company_roc: null,
            company_address: null,
        };
    },

    beforeMount() {
        this.currentInvoice();
        this.sendRequest();
    },

    mounted() { },

    created() { },

    computed: { },

    methods: {
        redirectPage() {
            this.$router.push("/user/subscription");
        },
        downloadInvoice() {
            const element = document.getElementById('print');
            const options = {
                margin: 0,
                filename: 'invoice-subscription-#' + this.$route.query.invoice + '.pdf',
                image: { type: 'jpeg', quality: 0.98 },
                html2canvas: { scale: 2 },
                jsPDF: { unit: 'in', format: 'a4', orientation: 'portrait' }
            };

            html2pdf().set(options).from(element).save();
        },
            


        async sendRequest() {
            try {
                await getInfoCompany().then((response) => {
                    if (response.data.success == true) {
                        this.company_name = response.data.data.name;
                        this.company_phone = response.data.data.phone;
                        this.email = response.data.data.email;
                        this.company_roc = response.data.data.roc;
                        this.company_address = response.data.data.address;
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
        async currentInvoice() {
            try {
                const dataPass = {
                    id: this.$route.query.invoice,
                };

                await listPackage(dataPass).then((response) => {
                    if (response.data.success == true) {
                        window.open(response.data.data.short_url, '_blank');
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },


        // uiux
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
    },
};
</script>