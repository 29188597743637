<style scoped>
.selected-type {
  border-bottom:2px solid #CA952B;
  width:100%;
}

.card-agent {
	background-color:#fefefe;
	border-radius: 10px;
}

.cagent-image {
	width: 30%;

}
.cagent-image>img {
	width: 80%;

}

.cagent-details {
	width: 50%;
	padding-left: 20px;
}

.cagent-ws {
	display: flex;
	width: 20%;
	padding-right: 5px;
	justify-content: flex-end;
}

.border-tab {
    border-bottom: 2px solid #C89835 !important;
}
.form-control::placeholder {
    color: #c9c9c9 !important;
}
.padding-container {
    padding: 1.5rem;
}

@media (max-width: 575px) {
    .padding-container {
        padding: 1.5rem 0.4rem 1.5rem 0.4rem;
    }
}
</style>

<template> 
    <div class="padding-container">

        <div class="card p-3 mb-3">       
            <div class="">
                <div class="px-4 d-flex row" style="align-items: center;">
                    <div class="p-0 col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style="cursor: pointer;">
                        <div :class="{ 'border-tab': tab1 }" style="border-bottom: #CCC solid 1px; text-align: center; height:50px; justify-content: center; align-items: center;" class="d-flex" @click="setActiveTab('manage')">
                            <h6 class="m-0">Manage User</h6>
                        </div>
                    </div>

                    <div class="p-0 col-6 col-sm-6 col-md-3 col-lg-3 col-xl-3" style="cursor: pointer;">
                        <div :class="{ 'border-tab': tab2 }" style="border-bottom: #CCC solid 1px; text-align: center; height:50px; justify-content: center; align-items: center;" class="d-flex" @click="setActiveTab('approval')">
                            <h6 class="m-0">Approve Agent</h6>
                        </div>
                    </div>
                </div>

                <div v-if="this.tab1" class="row p-3">
                    <div class="py-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Find user</div>

                    <div class="pt-2 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <input v-model="this.search" class="form-control" type="text" style="width: 100%; height: 38px" placeholder="Search Name"/>
                    </div>

                    <div class="pt-2 col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 d-flex">
                        <div>
                            <button @click="getAgent()" class="btn btn-primary btn-block" style="width: 120px;height: 37px" align="center">Search</button>
                        </div>
                        <div class="ps-2">
                            <button v-if="this.beenFilter" @click="resetFilter()" class="btn btn-tertiary btn-block" style="border: #CCC 1px solid; width: 100px;height: 37px" align="center">Reset</button>
                        </div>
                    </div>
                </div>

                <div v-if="this.tab2" class="row p-3">
                    <div class="py-2 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">Find new register agent or broker</div>

                    <div class="pt-2 col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                        <input v-model="this.search" class="form-control" type="text" style="width: 100%; height: 38px" placeholder="Search Name"/>
                    </div>

                    <div class="pt-2 col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 d-flex">
                        <div>
                            <button @click="getAgent()" class="btn btn-primary btn-block" style="width: 120px;height: 37px" align="center">Search</button>
                        </div>
                        <div class="ps-2">
                            <button v-if="this.beenFilter" @click="resetFilter()" class="btn btn-tertiary btn-block" style="border: #CCC 1px solid; width: 100px;height: 37px" align="center">Reset</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div v-if="this.tab1">
            <div class="p-3" style="width: 100%;" v-if="this.tableLoader1 == false">
                <div v-if="this.emptyListing">
                    <div class="row" style="justify-content: space-between">
                        <div class="mb-3 col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" v-for="(item, index) in this.dataAgent" :key="index">
                            <div class="card-agent row" style="box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px; height: 109px;">
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <div class="d-flex" style="justify-content: center; align-items: center; height: 100%;">
                                        <div><img style="border-radius:50%; width:80px;" :src="item.image || require('../../assets/images/user/avatar.png')" /></div>
                                    </div>
                                </div>
                                
                                <div class="col-7 col-sm-7 col-md-7 col-lg-7 col-xl-7">
                                    <div class="pt-3" style="font-weight: 500; font-size: 1rem">{{ item.name }}</div>
                                    <div class="" style="font-weight: 400; font-size: 0.8rem">{{ capitalizeFirstLetter(item.status) }}</div>
                                    <!-- <div class="" style="font-weight: 300; font-size: 0.8rem">{{ item.phone }}</div> -->
                                    <div class="pb-3" style="font-weight: 300; font-size: 0.7rem">{{ item.email }}</div>
                                </div>
                            
                                <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2 d-flex" style="align-items: center;">
                                    <div class="pt-2" style="cursor: pointer">
                                        <svg @click="initUser(item)"  data-bs-toggle="modal" data-bs-target="#editCredential" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#C89835" class="bi bi-pen-fill" viewBox="0 0 16 16">
                                            <path d="m13.498.795.149-.149a1.207 1.207 0 1 1 1.707 1.708l-.149.148a1.5 1.5 0 0 1-.059 2.059L4.854 14.854a.5.5 0 0 1-.233.131l-4 1a.5.5 0 0 1-.606-.606l1-4a.5.5 0 0 1 .131-.232l9.642-9.642a.5.5 0 0 0-.642.056L6.854 4.854a.5.5 0 1 1-.708-.708L9.44.854A1.5 1.5 0 0 1 11.5.796a1.5 1.5 0 0 1 1.998-.001"/>
                                        </svg>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="px-4 d-flex" style="flex-direction: column; align-items: center; width: 100%;">
                    <div>
                        <img style="border-radius: 5px; width: 300px;" alt="" src="../../assets/images/no_data_image.png" />
                    </div>
                    <div style="text-align: center;">
                        <h6>No result found!</h6>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex" style="flex-direction: column; justify-content: center;">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>
            </div>
        </div>
    
        <div v-if="this.tab2">
            <div class="p-3" style="width: 100%;" v-if="this.tableLoader2 == false">
                <div v-if="this.emptyListing2">
                    <div class="row" style="justify-content: space-between">
                        <div class="mb-3 col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5" v-for="(item, index) in this.dataAgent2" :key="index">
                            <div class="card-agent row" style="box-shadow: rgba(100, 100, 111, 0.1) 0px 7px 29px 0px; height: 145px;">
                                <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
                                    <div class="d-flex" style="justify-content: center; align-items: center; height: 100%;">
                                        <div><img style="border-radius:50%; width:80px;" :src="item.image || require('../../assets/images/user/avatar.png')" /></div>
                                    </div>
                                </div>
                                
                                <div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9">
                                    <div class="pt-3" style="font-weight: 500; font-size: 1rem">{{ item.name }}</div>
                                    <div class="" style="font-weight: 300; font-size: 0.8rem">{{ item.phone }}</div>
                                    <div class="" style="font-weight: 300; font-size: 0.7rem">{{ item.email }}</div>
                                    <div class="pt-3" style="cursor: pointer">
                                        <button @click="approveAgent(item.id)" class="btn btn-primary btn-block" style="max-width: 120px;height: 37px" align="center">Approve</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="px-4 d-flex" style="flex-direction: column; align-items: center; width: 100%;">
                    <div>
                        <img style="border-radius: 5px; width: 300px;" alt="" src="../../assets/images/no_data_image.png" />
                    </div>
                    <div style="text-align: center;">
                        <h6>No result found!</h6>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="d-flex" style="flex-direction: column; justify-content: center;">
                    <h6 class="mb-0 text-center">Please Wait...</h6>
                    <div class="loader-box">
                        <div class="loader-3"></div>
                    </div>
                </div>
            </div>
        </div>


        <div class="modal fade modal-bookmark" id="editCredential" tabindex="-1" role="dialog" aria-hidden="true" >
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Change Password</h5>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="p-4">
                        <div class="row">
                            <div class="py-2 col-form-label col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">Name: </div>
                            <div class="col-form-label col-9 col-sm-9 col-md-9 col-lg-10 col-xl-10">{{ this.selectName }}</div>
                            <div class="py-2 col-form-label col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">Username: </div>
                            <div class="col-form-label col-9 col-sm-9 col-md-9 col-lg-10 col-xl-10">{{ this.selectUsername }}</div>
                            <div class="py-2 col-form-label col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">Email: </div>
                            <div class="col-form-label col-9 col-sm-9 col-md-9 col-lg-10 col-xl-10">{{ this.selectEmail }}</div>
                            <div class="py-2 col-form-label col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">Phone No.:</div>
                            <div class="col-form-label col-9 col-sm-9 col-md-9 col-lg-10 col-xl-10">{{ this.selectPhone }}</div>
                            <div class="py-2 col-form-label col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">City: </div>
                            <div class="col-form-label col-9 col-sm-9 col-md-9 col-lg-10 col-xl-10">{{ this.selectCity }}</div>
                            <div class="py-2 col-form-label col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">State: </div>
                            <div class="col-form-label col-9 col-sm-9 col-md-9 col-lg-10 col-xl-10">{{ this.selectState }}</div>
                        </div>

                        <div class="form-group py-2">
                            <label class="col-form-label">Status Account</label>
                            <select class="form-select form-control-primary" name="select" required v-model="this.selectedStatus">
                                <option value="" disabled selected required > Select Status </option>
                                <option value="active">Active</option>
                                <option value="suspend">Suspend</option>
                            </select>
                        </div>
                        <div class="form-group py-2">
                            <label class="col-form-label">Password</label>
                            <input class="form-control" v-model="this.password" type="password" required="" placeholder="********"/>
                        </div>
                        <div class="form-group py-2">
                            <label class="col-form-label">Confirm Password</label>
                            <input class="form-control" v-model="this.repassword" type="password" required="" placeholder="********"/>
                        </div>
                    </div>
                    <div class="row p-3">
                        <div class="col-12 d-flex" style="justify-content: flex-end">
                            <div>
                                <button @click="changePass()" data-bs-dismiss="modal" class="btn btn-color" type="button" style="width: 100%; color: #fefefe; background-color: #04318c !important; border-color: #04318c !important;">
                                    Confirm
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { 
    listofagent,
    updatePassword,
    updateStatus,
    canApproveAgent,
} from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    components: {
    },
    data() {
        return {
            tab1: true,
            tab2: false,
            activeTab: "manage",
			tableLoader1: false,
			tableLoader2: false,

            selectedStatus: "",
            prevStatus: "",
			emptyListing: false,
			emptyListing2: false,
			beenFilter: false,
			search: null,
			dataAgent: [],
			dataAgent2: [],
			scrollY: 0,
			action: null,
			status: 0,
			point: 0,
			buffer: false,
			password: null,
			repassword: null,
            userId: null,
            selectName: null,
            selectEmail: null,
            selectUsername: null,
            selectState: null,
            selectCity: null,
            selectPhone: null,
        };
    },
    computed: {
    },
    mounted() {
        this.getAgent();
        this.getRegisterAgent();
    },
	beforeDestroy() {
	},
    created() {
    },
    methods: {
        setActiveTab(cat) {
            this.activeTab =  cat;
            if (this.activeTab == 'manage') {
                this.tab1 = true;
                this.tab2 = false;
            } else if (this.activeTab == 'approval') {
                this.tab1 = false;
                this.tab2 = true;
            }
        },

        capitalizeFirstLetter(text) {
            if (!text) return '';
            return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
        },

        async changePass() {
            if (this.prevStatus != this.selectedStatus) {
                try {
                    const dataPass = {
                        user_id : this.userId,
                        status : this.selectedStatus,
                    };
                    await updateStatus(dataPass).then((response) => {
                        if (response.data.success == true) {
                            this.notificationSuccess("Status changed");
                            this.selectedStatus = "";
                            this.prevStatus = "";
                            this.getAgent();
                        } else {
                            this.notificationError(response.data.message);
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            }
            if (this.password != null && this.repassword != null && this.password == this.repassword) {
                try {
                    const dataPass = {
                        user_id : this.userId,
                        password : this.password,
                        re_password : this.repassword,
                    };
                    await updatePassword(dataPass).then((response) => {
                        if (response.data.success == true) {
                            this.notificationSuccess("Password changed");
                            this.password = null;
                            this.repassword = null;
                        } else {
                            this.notificationError(response.data.message);
                        }
                    });
                } catch (error) {
                    this.notificationError("Something went wrong!");
                }
            } else if (this.password == null && this.repassword == null){
                // nothing
            } else {
                this.notificationError("Invalid password!");
            }
        },

        initUser(item) {
            this.selectName = item.name;
            this.selectEmail = item.email;
            this.selectUsername = item.username;
            this.selectState = item.state;
            this.selectCity = item.city;
            this.selectPhone = item.phone;

            this.selectedStatus = item.status;
            this.prevStatus = item.status;
			this.password = null;
			this.repassword = null;
            this.userId = item.id;
        },

        //TOAST NOTIFICATION
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },
        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },

        async getAgent() {
            try {
				this.tableLoader1 = true;

                const dataPass = {
					type : null,
                    value : this.search,
                    role: null,
                };

				if (this.search != null) {
					this.beenFilter = true;
				}

                await listofagent(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.dataAgent = response.data.data;
						if (response.data.data.length === 0) {
                            this.emptyListing = false;
                        } else {
                            this.emptyListing = true;
                        }
                    } else {
                        this.notificationError(response.data.message);
                    }
					this.tableLoader1 = false;
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
		
        async getRegisterAgent() {
            try {
				this.tableLoader2 = true;

                const dataPass = {
					type : null,
                    value : this.search,
                    role: '2',
                    approved: "0",
                };

				if (this.search != null) {
					this.beenFilter = true;
				}

                await listofagent(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.dataAgent2 = response.data.data;
						if (response.data.data.length === 0) {
                            this.emptyListing2 = false;
                        } else {
                            this.emptyListing2 = true;
                        }
                    } else {
                        this.notificationError(response.data.message);
                    }
					this.tableLoader2 = false;
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        async approveAgent(id) {
            try {
                const dataPass = {
                    userId : id,
                };
                await canApproveAgent(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.getRegisterAgent();
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },

        resetFilter() {
            this.search = null;
            this.beenFilter = false;
            this.getAgent();
        },
    },
};
</script>
