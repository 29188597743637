<template>
    <div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 p-0">
                    <div class="login-card">
                        <div>
                            <div>
                                <a class="logo">
                                    <img height="500" class="img-fluid for-light" src="../assets/images/logo/logo_login.png" alt="loginpage" style="width:40%;"/>
                                    <img class="img-fluid for-dark" src="../assets/images/logo/logo_dark.png" alt="loginpage"/>
                                </a>
                            </div>
                            <div class="login-main">
                                <form class="theme-form">
                                    <div>
                                        <h4>Reset your password</h4>
                                        <p>Makesure password match</p>
                                        <div class="form-group">
                                            <label class="col-form-label">New Password</label>
                                            <input class="form-control" v-model="this.newPassword" type="password" required="" placeholder="********"/>
                                        </div>
                                        <div class="form-group">
                                            <label class="col-form-label">Confirm Password</label>
                                            <input class="form-control" v-model="this.newConfirmPassword" type="password" required="" placeholder="********"/>
                                        </div>
                                        <div class="form-group mb-0">
                                            <div class="text-end mt-3">
                                                <button class="btn btn-primary btn-block w-100" type="button" @click="resetPassword()">Confirm</button>
                                            </div>
                                        </div>
                                        <div class="pt-3" style="font-size:0.8rem;font-weight:400" align="center">
                                            Back to<span class="font-primary" style="cursor:pointer;" @click="openRegisterTab('login')"> Sign In</span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { resetLinkPassword } from "@/helper/apiservice/axios-http";
import { toast } from "vue3-toastify";

export default {
    name: "Reset Password",

    data() {
        return {
            showPassword: false,
            result: {
                email: "",
                password: "",
            },
            user: {
                remember: {
                    value: false,
                    errormsg: "",
                },
                email: {
                    value: "",
                    errormsg: "",
                },
                password: {
                    value: "",
                    errormsg: "",
                },
            },
            newName: null,
            newPhone: null,
            newEmail: null,
            register: false,
            newUsername: null,
            newPassword: null,
            newConfirmPassword: null,
            newRole: "3",
        };
    },

    created() {},

    computed: {
        passwordFieldType() {
            return this.showPassword ? "text" : "password";
        },
        showPasswordText() {
            return this.showPassword ? "Hide" : "Show";
        },
    },

    methods: {
        redirect1() {
            this.$router.push("/web/homepage");
        },

        notificationError(message) {
            toast(message, {
                theme: "colored",
                type: "error",
            });
        },
        notificationWarning(message) {
            toast(message, {
                theme: "colored",
                type: "warning",
            });
        },
        notificationSuccess(message) {
            toast(message, {
                theme: "colored",
                type: "success",
            });
        },

        validEmail: function (email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },

        togglePasswordVisibility() {
            this.showPassword = !this.showPassword;
        },

        openRegisterTab(state) {
            this.register = state;
        },

        async resetPassword() {
            try {
                const dataPass = {
                    token : this.$route.query.token,
                    email : this.$route.query.email,
                    password : this.newPassword,
                    password_confirmation : this.newConfirmPassword,
                };
                await resetLinkPassword(dataPass).then((response) => {
                    if (response.data.success == true) {
                        this.notificationSuccess("Success");
                        setTimeout(() => {
                            this.$router.push("/auth/login");
                        }, 1000);
                        
                    } else {
                        this.notificationError(response.data.message);
                    }
                });
            } catch (error) {
                this.notificationError("Something went wrong!");
            }
        },
    },
};
</script>
